import {
  ComponentPropsWithoutRef,
  ForwardedRef,
  forwardRef,
  useState,
  useEffect,
} from 'react';
import { useVisibility, useRemixHasNavigated } from '~/shared/hooks';
import { VisibilityName } from '~/store/visibility';
import { InboxButton } from '~/features/Inbox';
import { UserMenu } from './UserMenu';
import { UserMobileMenu } from './UserMobileMenu';
import { Popover, positionRight } from '@setel/web-ui';
import { useIsAuthorized, useUserProfile } from '~/features/Auth';

const UserContextButton = forwardRef<
  HTMLButtonElement,
  Pick<ComponentPropsWithoutRef<'button'>, 'onClick'>
>(({ onClick }, ref) => {
  const { data: profile } = useUserProfile();

  return (
    <button
      ref={ref}
      type="button"
      aria-label="Open Menu"
      className="inline-flex p-2 -m-2"
      onClick={onClick}
    >
      <span className="w-6 h-6 bg-brand text-white text-sm font-medium rounded-full inline-flex justify-center items-center">
        {profile?.fullName?.slice(0, 1).toUpperCase()}
      </span>
    </button>
  );
});

export const UserNavigation = () => {
  const isAuth = useIsAuthorized();

  const modal = useVisibility(VisibilityName.USER_NAVIGATION);

  return (
    <>
      {/* Mobile view */}
      <div className="xl:hidden">
        <UserContextButton onClick={modal.open} />
        {modal.isOpen && (
          <UserMobileMenu isOpen={modal.isOpen} onDismiss={modal.close} />
        )}
      </div>

      {/* Desktop view */}
      {isAuth && (
        <div className="hidden xl:flex gap-4 items-center">
          <UserNavigationPopover />
        </div>
      )}
    </>
  );
};

function UserNavigationPopover() {
  const [isOpen, setIsOpen] = useState(false);
  const hasNavigated = useRemixHasNavigated();

  useEffect(() => {
    // close Menu after page navigation finished
    if (isOpen && hasNavigated) {
      setIsOpen(false);
    }
  }, [isOpen, hasNavigated]);

  return (
    <>
      <InboxButton className="px-2 rounded" />
      <Popover isOpen={isOpen} onOpenChange={setIsOpen}>
        <Popover.Trigger
          render={({ ref, ...props }) => (
            <UserContextButton
              {...props}
              aria-label="Open Menu"
              onClick={() => setIsOpen(!isOpen)}
              ref={ref as ForwardedRef<HTMLButtonElement>}
            />
          )}
        />
        <Popover.Content
          position={positionRight}
          arrowOffsetX={24}
          wrapperClass={isOpen ? 'z-50' : ''}
          className="hidden xl:block min-w-[300px]"
        >
          <div className="rounded-2xl overflow-x-hidden">
            <UserMenu />
          </div>
        </Popover.Content>
      </Popover>
    </>
  );
}

import { Button, Checkbox } from '@setel/web-ui';
import { Input } from '~/components/Form';
import { FormField } from '../Formik';
import { useIntroduceYourselfForm } from './hooks/useIntroduceYourselfForm';
import { EVENT_CHANNELS, EVENT_TYPES } from '~/shared/analytics.events';
import { RootNavigationRoute } from '../Navigation';
import { trackMesraUserEvent, trackUserEvent } from '../../shared/analytics';
import { useState } from 'react';

export type IntroduceYourselfProps = {
  formik: ReturnType<typeof useIntroduceYourselfForm>;
};

export const IntroduceYourself = ({ formik }: IntroduceYourselfProps) => {
  const [isAgreeTermAndCondition, setIsAgreeTermAndCondition] = useState(false);
  return (
    <form
      className="flex flex-col items-center px-3"
      onSubmit={(values) => {
        formik.handleSubmit(values);
        window.location.pathname ===
          RootNavigationRoute.MESRA_CARD_REGISTER_LANDING_PAGE &&
          trackUserEvent(
            EVENT_TYPES.cardless_mesra_continue_introduce_yourself,
            {},
            [EVENT_CHANNELS.MIXPANEL]
          );
        trackMesraUserEvent(
          EVENT_TYPES.cardless_mesra_continue_introduce_yourself,
          {},
          [
            EVENT_CHANNELS.MESRA_META_PIXEL,
            EVENT_CHANNELS.MESRA_GOOGLE_GTAG,
            EVENT_CHANNELS.MESRA_DV360_GTAG,
          ]
        );
      }}
    >
      <h1 className="text-xl text-gray-800 text-center font-medium mb-8">
        Introduce yourself
      </h1>
      <FormField
        {...formik}
        label="Full name"
        name="fullName"
        placeholder="E.g. Muhammad Yusof"
        component={Input}
      />
      <FormField
        {...formik}
        label="Email (Optional)"
        name="email"
        placeholder="E.g. Yusof@gmail.com"
        component={Input}
      />
      <FormField
        {...formik}
        label="Voucher code (Optional)"
        name="promoCode"
        helperText="Enter voucher code or friends referral code to get rewarded."
        placeholder="Enter voucher code"
        component={Input}
      />
      <div className="pt-5">
        <Checkbox
          name="agreeTermAndCondition"
          label={
            <p className="text-xs leading-[1.125rem] font-normal text-[#525B65]">
              I have read and agree to &nbsp;
              <a
                href="https://www.setel.com/terms"
                className="text-brand"
                rel="noopener noreferrer"
                target="_blank"
              >
                Setel Terms & Conditions
              </a>
              , &nbsp;
              <a
                href="https://www.mymesra.com.my/clients/asset_E09476E5-9A3F-437E-995E-88B99F82B020/contentms/img/pdf/terms-conditions/Kad%20Mesra%20T&C_FINAL%20300420.pdf"
                className="text-brand"
                rel="noopener noreferrer"
                target="_blank"
              >
                Mesra Terms & Conditions
              </a>
              , and&nbsp;
              <a
                href="https://www.setel.com/privacy"
                className="text-brand"
                rel="noopener noreferrer"
                target="_blank"
              >
                Setel Group Privacy Statement
              </a>
              .
            </p>
          }
          checked={isAgreeTermAndCondition}
          onChangeValue={(checked) => {
            setIsAgreeTermAndCondition(checked);
            formik.setFieldValue('agreeTermAndCondition', checked);
            if (checked === true) {
              formik.setFieldValue('agreeTermAndConditionAt', new Date());
            }
          }}
        />
        <div className="pl-[1.85rem] text-xs leading-[1.125rem] font-normal text-[#F44336] min-h-5">
          {formik.errors?.agreeTermAndCondition
            ? 'Please tick the checkbox to continue'
            : ''}
        </div>
      </div>
      <div className="pt-5">
        <Checkbox
          name="emailSubscriptionStatus"
          label={
            <p className="mt-[1px] text-xs leading-[1.125rem] font-normal text-[#525B65]">
              Send Setel promos and deals to my email.
            </p>
          }
          disabled={!formik.values.email}
          checked={formik.values.emailSubscriptionStatus}
          onChangeValue={(checked) => {
            formik.setFieldValue('emailSubscriptionStatus', checked);
          }}
        />
      </div>
      {formik.generalError && (
        <span className="text-xs block my-1 text-error">
          {formik.generalError}
        </span>
      )}
      <Button
        type="submit"
        isLoading={formik.isSubmitting || formik.status?.isValidating}
        className="mt-5 mb-4 w-full"
      >
        Continue
      </Button>
    </form>
  );
};

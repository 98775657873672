import type appboy from '@braze/web-sdk';
import { ChevronDownIcon, MailIcon, TrashIcon } from '@setel/web-ui';
import classNames from 'classnames';
import { Icon } from '~/components';
import { RootNavigationRoute } from '~/features/Navigation';
import { useState } from 'react';
import { routeTo } from '~/shared';
import { ConfirmDeleteModal } from './ConfirmDeleteModal';
import { extractMessageId } from './extractMessageId';
import { IconMenu } from './IconMenu';
import { IconMenuItem } from './IconMenuItem';
import { IconMenuItems } from './IconMenuItems';
import { useInboxMessages } from './useInboxMessages';
import { useNavigate } from '@remix-run/react';

type InboxMessageProps = { message: appboy.ClassicCard; onClose: () => void };

export const InboxMessage = ({ message, onClose }: InboxMessageProps) => {
  const navigate = useNavigate();

  const { markAsRead, deleteMessage } = useInboxMessages();
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);

  const onClickMessage = () => {
    const messageId = extractMessageId(message.id);
    if (!messageId) return;
    markAsRead(message);
    onClose();
    navigate(routeTo(RootNavigationRoute.INBOX_VIEW_MESSAGE, { messageId }));
  };

  return (
    <div
      className={classNames(
        'relative px-5 cursor-pointer hover:bg-gray-200 items-center',
        'inbox-message'
      )}
      onClick={onClickMessage}
    >
      <div
        className={classNames(
          'bg-brand relative flex justify-center items-center w-11 h-11 rounded-full',
          { 'bg-opacity-50': message.viewed }
        )}
      >
        {!message.viewed && (
          <span className="absolute top-0 right-0 bg-error h-3 w-3 rounded-xl border-white border-2">
            &nbsp;
          </span>
        )}
        <Icon name="mail" className="text-white" />
      </div>
      <div data-message className="grid border-gray-300 border-b">
        <div className="py-4">
          <p
            className={classNames(
              'text-regular truncate',
              message.viewed
                ? 'text-gray-medium font-normal'
                : 'text-gray-dark font-medium'
            )}
          >
            {message.title}
          </p>
          <p className="text-sm text-gray-light truncate">
            {message.description}
          </p>
        </div>
        <IconMenu
          aria-label="Message Actions"
          labelIcon={
            <ChevronDownIcon className="w-5 h-5 m-1 text-gray-medium" />
          }
          className="w-full self-center"
        >
          <IconMenuItems popoverClass="z-100">
            <IconMenuItem
              onSelect={() => setIsConfirmVisible(true)}
              className="text-error"
            >
              <TrashIcon /> Delete message
            </IconMenuItem>
            {!message.viewed && (
              <IconMenuItem onSelect={() => markAsRead(message)}>
                <MailIcon className="text-gray-medium" /> Mark as read
              </IconMenuItem>
            )}
          </IconMenuItems>
        </IconMenu>
      </div>
      <ConfirmDeleteModal
        header="Delete message?"
        body="Once you delete this message in your inbox, you won’t be
          able to undo it."
        isOpen={isConfirmVisible}
        onClose={() => setIsConfirmVisible(false)}
        onConfirm={() => {
          deleteMessage(message);
          setIsConfirmVisible(false);
        }}
      />
    </div>
  );
};

export default function MobileGradient() {
  return (
    <div
      style={{
        backgroundImage:
          'linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8))',
      }}
      className="absolute w-full h-1/2 top-0 md:hidden"
    />
  );
}

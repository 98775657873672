import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { zendeskTagSlice, ZendeskTagsName, ZendeskTagState } from '~/store';

export const useZendeskTag = () => {
  const newState: ZendeskTagState = {
    prevTag: undefined,
    currentTag: undefined,
  };

  const dispatch = useDispatch();

  return {
    set: (name: ZendeskTagsName) => {
      newState.currentTag = name;
      dispatch(zendeskTagSlice.actions.set(newState));
    },
    reset: () => {
      dispatch(zendeskTagSlice.actions.reset());
    },
  };
};

export const useZendeskTagEffect = (name: ZendeskTagsName) => {
  const zendeskTag = useZendeskTag();
  useEffect(() => {
    zendeskTag.set(name);
    return () => {
      zendeskTag.reset();
    };
  }, [name, zendeskTag]);
};

import { FC } from 'react';
import * as React from 'react';
import Transition from '../Transition';
import { AnimationProps } from '../transition.const';

export const FadeInDown: FC<React.PropsWithChildren<AnimationProps>> = ({
  children,
  ...rest
}) => {
  return (
    <Transition
      {...rest}
      enter="transition ease-out transform"
      enterFrom="opacity-0 -translate-y-full"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in transform"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-full"
    >
      {children}
    </Transition>
  );
};

import { apiClient } from '~/shared/axios';
import { throwErrorData } from '~/domain/error';
import { SendDownloadLinkResponse } from './sms.type';

export const sendDownloadLink = (phone: string) =>
  apiClient
    .post<SendDownloadLinkResponse>(`/sms/send-download-link`, {
      phone,
    })
    .then((resp) => resp.data)
    .catch(throwErrorData);

import { positionRight } from '@setel/web-ui';
import cn from 'classnames';
import {
  MenuNavigation,
  RootNavigationRoute,
  useNavItemsHook,
} from '~/features/Navigation';
import { Suspense, type ReactNode } from 'react';
import { IconProp } from '../Icon';
import { TopNavLink, TopNavLinkProps } from './TopNavLink';
import { ErrorBoundary } from '~/components/ErrorBoundary';

export interface NavLinkPropsWithLabel
  extends Omit<TopNavLinkProps, 'children'> {
  label: string;
  icon?: IconProp['name'];
  iconUrl?: string;
}

type TopNavProps = {
  children: ReactNode;
  className?: string;
};

export const TopNav = ({ children, className }: TopNavProps) => {
  return (
    <div id="header" className={cn('bg-white shadow', className)}>
      <header className="container mx-auto h-header flex items-center justify-between flex-wrap">
        <div className="flex-1 xl:flex-none">
          <MenuNavigation />
        </div>

        <div className="flex-1 xl:flex-none inline-flex justify-center">
          <a
            href={RootNavigationRoute.HOME}
            className="flex items-center flex-shrink-0 text-white"
          >
            <img
              aria-label="Home page"
              alt="Setel logo"
              src="/assets/logo.svg"
              width="60"
              height="20"
              className="sm:w-[103px]"
            />
          </a>
        </div>

        <nav className="ml-4 hidden xl:block flex-grow">
          <ErrorBoundary fallback={<div />}>
            <Suspense>
              <NavMenu />
            </Suspense>
          </ErrorBoundary>
        </nav>

        <div className="flex-1 xl:flex-none inline-flex justify-end">
          {children}
        </div>
      </header>
    </div>
  );
};

function NavMenu() {
  const { data: links } = useNavItemsHook({ suspense: true });

  return (
    <ul className="flex gap-2 text-base">
      {links.map(({ label, icon, iconUrl, ...link }, i, arr) => (
        <li key={i}>
          <TopNavLink
            {...link}
            popoverPosition={i === arr.length - 1 ? positionRight : undefined}
          >
            {label}
          </TopNavLink>
        </li>
      ))}
    </ul>
  );
}

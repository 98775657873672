import cn from 'classnames';
import { ForwardedRef, useEffect, useState } from 'react';
import { Modal, Popover, positionRight } from '@setel/web-ui';
import { useVisibility } from '~/shared';
import { VisibilityName } from '~/store';
import { InboxContent } from './InboxContent';
import { InboxHeader } from './InboxHeader';
import { useInboxMessages } from './useInboxMessages';
import { MessageCountBadge } from './MessageCountBadge';
import { GhostButton, MobileMenuItem } from '~/components';
import { useTranslation } from 'react-i18next';

export type InboxButtonProps = { className?: string };

export const InboxButton = ({ className }: InboxButtonProps) => {
  let { t } = useTranslation();
  const userNavigationModal = useVisibility(VisibilityName.USER_NAVIGATION);
  const modal = useVisibility(VisibilityName.INBOX);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { messages, controlCards, unreadCount, markAsRead } =
    useInboxMessages();

  useEffect(() => {
    if (!modal.isOpen || !isPopoverOpen) return;
    // If you use a custom integration for Content Cards, your integration needs to log impressions when a Control Card would have been seen.
    // https://www.braze.com/docs/developer_guide/platform_integration_guides/web/content_cards/integration/#control-group
    controlCards.forEach(markAsRead);
  }, [modal.isOpen, isPopoverOpen, markAsRead, controlCards]);

  return (
    <>
      {/* Mobile view */}
      <MobileMenuItem
        onClick={() => {
          userNavigationModal.close();
          modal.open();
        }}
        className={cn('flex items-center xl:hidden', className)}
        leftIcon="inbox"
      >
        {t('navbar.inbox')}
        <MessageCountBadge unreadCount={unreadCount} />
      </MobileMenuItem>
      <Modal
        aria-label={t('navbar.inbox')}
        isOpen={modal.isOpen}
        onDismiss={modal.close}
        overlayClassName="xl:hidden"
      >
        <Modal.Body className="p-0">
          <InboxHeader
            unreadCount={unreadCount}
            messageCount={messages.length}
            onClose={modal.close}
          />
          <InboxContent messages={messages} onClose={modal.close} />
        </Modal.Body>
      </Modal>

      {/* Desktop view */}
      <Popover isOpen={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <Popover.Trigger
          render={({ ref, ...props }) => (
            <GhostButton
              {...props}
              onClick={() => {
                userNavigationModal.close();
                setIsPopoverOpen(!isPopoverOpen);
              }}
              className={cn('hidden xl:flex items-center', className)}
              ref={ref as ForwardedRef<HTMLButtonElement>}
              leftIcon="inbox"
            >
              {t('navbar.inbox')}
              <MessageCountBadge unreadCount={unreadCount} />
            </GhostButton>
          )}
        />

        <Popover.Content
          position={positionRight}
          wrapperClass={isPopoverOpen ? 'z-50' : ''}
          className="hidden xl:block w-[380px]"
        >
          <InboxHeader
            unreadCount={unreadCount}
            messageCount={messages.length}
            onClose={() => setIsPopoverOpen(false)}
          />
          <InboxContent
            messages={messages}
            onClose={() => setIsPopoverOpen(false)}
          />
        </Popover.Content>
      </Popover>
    </>
  );
};

import cn from 'classnames';

export interface GalleryImageProps {
  url: string;
  isActive?: boolean;
  isPagination?: boolean;
}

export const GalleryImage = ({
  url,
  isActive,
  isPagination,
}: GalleryImageProps) => (
  <div
    className={cn('aspect-w-4 aspect-h-3', {
      'mt-2': isPagination,
    })}
  >
    <img
      alt=""
      src={url}
      className={cn(
        'md:border border-opacity-10 border-gray-light md:rounded-lg ',
        'bg-center bg-no-repeat bg-cover object-cover',
        {
          'shadow-gallery': isActive,
        }
      )}
    />
  </div>
);

import { ActionSheet } from '@setel/web-ui';
import { useVisibility } from '~/shared/hooks';
import { SelectProps } from '../select.type';
import { SelectSheetOption } from './SelectSheetOption';
import { Icon } from '~/components/Icon';
import { VisibilityName } from '~/store';

export const SelectSheet = ({ options, value, onChange }: SelectProps) => {
  const sortBottomSheet = useVisibility(VisibilityName.BOTTOM_SHEET);
  return (
    <>
      <div
        onClick={sortBottomSheet.open}
        className="flex w-full justify-between md:hidden bg-white p-3 text-gray-800 shadow-xs border rounded"
      >
        <span>{options.find((option) => option.value === value)?.label}</span>
        <Icon name="arrow" />
      </div>
      <ActionSheet
        header="Sort"
        isOpen={sortBottomSheet.isOpen}
        onDismiss={sortBottomSheet.close}
      >
        <ActionSheet.Content>
          {options.map((option) => (
            <SelectSheetOption
              key={option.value}
              id={String(option.value)}
              label={option.label}
              checked={option.value === value}
              onChange={() => {
                onChange(option.value);
                sortBottomSheet.close();
              }}
            />
          ))}
        </ActionSheet.Content>
      </ActionSheet>
    </>
  );
};

import { defaultOnError, throwWPError } from '~/domain/error';
import { Menu, MenuItem } from '~/domain/wordpress/menu.type';
import { useMemo } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { apiClient } from '~/shared/axios';
import { runTimeConfig } from '~/shared/runtime-config';
import { TOP_NAV_LINKS } from './navigation.const';
import type { NavLinkPropsWithLabel } from '~/components/TopNav/TopNav';
import { useTranslation } from 'react-i18next';

export const useMenus = {
  queryKey: 'listMenus',
  queryFn: async (language: string) => {
    return apiClient
      .get<Menu[]>('/wp/v2/_/menus', {
        baseURL: runTimeConfig.WORDPRESS.API_URL,
        public: true,
        withInterfaceHeaders: false,
        params: { lang: language },
      })
      .then((resp) =>
        resp.data.map((menu) => ({
          ...menu,
          items: buildItemsTree(menu.items),
        }))
      )
      .catch(throwWPError);
  },
};

interface HookOptions extends Pick<UseQueryOptions, 'suspense'> {}

const useMenusHook = ({ suspense }: HookOptions = {}) => {
  const { i18n } = useTranslation();
  return useQuery(
    [useMenus.queryKey, i18n.language],
    () => useMenus.queryFn(i18n.language),
    {
      onError: defaultOnError,
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      retry: 2,
      // prevent throwing to error boundary so we can provide fallback data right here
      useErrorBoundary: () => false,
      suspense,
    }
  );
};

export const useNavItemsHook = ({ suspense }: HookOptions = {}) => {
  const { data, isLoading, ...rest } = useMenusHook({
    suspense,
  });
  const mainMenuItems = data?.find((e) => e.slug === 'main-menu')?.items;

  // intersects with our defined nav links
  const menuItems = useMemo<NavLinkPropsWithLabel[]>(
    () =>
      mainMenuItems
        ? mainMenuItems.map((item) => ({
            items: item.items,
            // mapping to compat with NavLinkProps
            href: item.url,
            target: item.target || undefined,
            iconUrl: item.icon_url || undefined,
            // find defined links & replace with our data
            // .eg path, icon, activeClassName .etc
            ...TOP_NAV_LINKS.find(({ label }) => label === item.title.trim()),
            // prefer provided menu title
            label: item.title.trim(),
          }))
        : TOP_NAV_LINKS,
    [mainMenuItems]
  );

  return { data: menuItems, isLoading, ...rest };
};

function buildItemsTree(items: MenuItem[], parentId = 0): MenuItem[] {
  let branch: MenuItem[] = [];

  for (let item of items) {
    if (item.menu_item_parent === parentId) {
      const children = buildItemsTree(items, item.id);
      if (children) {
        item.items = children;
      }
      branch.push(item);
    }
  }
  return branch;
}

import { forwardRef, ReactNode, RefObject, useState } from 'react';
import type { Slider as SlickSlider } from '~/components/Slick';
import type { Image } from '~/shared';
import { Slider } from '../Slider';
import { GalleryCounter } from './GalleryCounter';
import { GalleryImage, GalleryImageProps } from './GalleryImage';
import MobileGradient from './MobileGradient';

export type MainProps = {
  images: Image[];
  imageComponent?: (props: GalleryImageProps) => JSX.Element;
  extra?: ReactNode;
  pagination: RefObject<SlickSlider>;
};

export const Main = forwardRef<SlickSlider, MainProps>(
  ({ pagination, images, extra, imageComponent }, ref) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const ImgComp = imageComponent ?? GalleryImage;

    return (
      <div className="relative">
        <Slider
          beforeChange={(_, next) => pagination.current?.slickGoTo(next)}
          afterChange={setActiveIndex}
          ref={ref}
          arrows={false}
        >
          {images.map(({ url }) => (
            <div className="relative" key={url}>
              <ImgComp url={url} />
              <MobileGradient />
              {extra}
            </div>
          ))}
        </Slider>
        <GalleryCounter current={activeIndex} total={images.length} />
      </div>
    );
  }
);

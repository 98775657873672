import { useCallback, useEffect, type ReactNode } from 'react';
import { useSelector } from 'react-redux';
import type { Config } from '~/shared';
import type { RootState } from '~/store';
import { useUserProfile, useIsAuthorized } from '~/features/Auth/hooks';
import { ClientOnly } from 'remix-utils/client-only';
import {
  ZendeskWidgetProvider,
  ZendeskWidgetScript,
  useZendeskWidgetCtx,
} from './ZendeskWidget';
import { RootNavigationRoute } from '~/features/Navigation';
import { trackUserEvent } from '~/shared';
import { EVENT_CHANNELS, EVENT_TYPES } from '~/shared/analytics.events';
import { useLocation } from '@remix-run/react';

const CHAT_OPENED = 'Chat Opened';
const CHAT_REQUEST_FORM_SUBMITTED = 'Chat Request Form Submitted';

interface Window {
  zESettings?: ZendeskSettings;
  zE?: ZendeskWidget;
}

type ZendeskEventType = [string, string] | [string, string, any];

function sendZendeskEvent({
  event,
  fallback,
}: {
  event: ZendeskEventType;
  fallback?: ZendeskEventType;
}) {
  try {
    if (window?.zE) {
      // detecting the current Zendesk mode can be a bit tricky.
      // the simplest method is using a try-catch block.
      // in the future, we will only use messenger mode, so the webWidget should not be run
      try {
        const [type, command, payload] = event;

        window.zE(
          // @ts-ignore-errors
          type,
          command,
          payload
        );
      } catch {
        if (fallback) {
          const [type, command, payload] = fallback;

          window.zE(
            // @ts-ignore-errors
            type,
            command,
            payload
          );
        }
      }
    }
  } catch (e: any) {
    // we make sure that we don't crash the app
    console.error(e.message);
  }
}

export function EnableZendeskWidget({
  KEY,
  ENABLED,
  children,
}: Config['ZENDESK_WIDGET_CONFIG'] & { children?: ReactNode }) {
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === RootNavigationRoute.MESRA_CARD_REGISTER_LANDING_PAGE
    ) {
      trackUserEvent(EVENT_TYPES.cardless_mesra_pageview_chat, {}, [
        EVENT_CHANNELS.MIXPANEL,
      ]);
    }
  }, [location]);

  return (
    <ZendeskWidgetProvider>
      {children}

      <ZendeskWidgetScript KEY={KEY} ENABLED={ENABLED} />
      <ClientOnly>
        {() => {
          return (
            <>
              <PrefillZEChatWidget />
              <StartChatZEChatWidget />
            </>
          );
        }}
      </ClientOnly>
    </ZendeskWidgetProvider>
  );
}

function PrefillZEChatWidget() {
  const { isZeReady } = useZendeskWidgetCtx();
  const { data: profile } = useUserProfile();
  const isAuthorized = useIsAuthorized();
  const currentTag = useSelector(
    ({ zendeskTag }: RootState) => zendeskTag.currentTag
  );

  const preFillChatWidget = useCallback(() => {
    const data = {
      name: {
        value: profile?.fullName,
        readOnly: true,
      },
      email: {
        value: profile?.email,
        readOnly: true,
      },
      phone: {
        value: profile?.phone,
        readOnly: true,
      },
    };

    sendZendeskEvent({
      event: ['messenger:set', 'conversationFields', data],
      fallback: ['webWidget', 'prefill', data],
    });
  }, [profile]);

  useEffect(() => {
    if (!isZeReady) {
      return;
    }

    sendZendeskEvent({
      event: [
        'messenger:set',
        'conversationTags',
        () => {
          if (isAuthorized) {
            preFillChatWidget();
          }
        },
      ],
      fallback: [
        'webWidget:on',
        'userEvent',
        (userEvent: any) => {
          if (userEvent.action === CHAT_OPENED) {
            if (isAuthorized) {
              preFillChatWidget();
            }

            if (currentTag) {
              sendZendeskEvent({
                event: ['webWidget', 'chat:addTags', [currentTag]],
              });
            }
          }
        },
      ],
    });
  }, [isZeReady, currentTag, preFillChatWidget, isAuthorized]);

  return null;
}

function StartChatZEChatWidget() {
  const { isZeReady } = useZendeskWidgetCtx();

  const submitFormChatWidget = useCallback(() => {
    sendZendeskEvent({
      event: [
        'webWidget:on',
        'userEvent',
        (userEvent: any) => {
          if (userEvent.action === CHAT_REQUEST_FORM_SUBMITTED) {
            if (
              location.pathname ===
              RootNavigationRoute.MESRA_CARD_REGISTER_LANDING_PAGE
            ) {
              trackUserEvent(EVENT_TYPES.cardless_mesra_start_chat, {}, [
                EVENT_CHANNELS.MIXPANEL,
              ]);
            }
          }
        },
      ],
    });
  }, [isZeReady]);

  useEffect(() => submitFormChatWidget(), [submitFormChatWidget]);

  return null;
}

type ZendeskWidgetSettingsConfig = ZendeskSettings['webWidget'];

const defaultZendeskWidgetSettings: ZendeskWidgetSettingsConfig = {
  zIndex: 9999999999999,
  color: {
    launcherText: '#FFFFFF',
  },
  offset: { mobile: { vertical: '0px' } },
};

export function updateZendeskWidgetSettings(
  config: ZendeskWidgetSettingsConfig
) {
  sendZendeskEvent({
    event: [
      'webWidget',
      'updateSettings',
      {
        webWidget: {
          ...defaultZendeskWidgetSettings,
          ...config,
        },
      },
    ],
  });
}

export function useZendeskWidgetSettings() {
  const { isZeReady } = useZendeskWidgetCtx();

  const update = useCallback(
    (settings: ZendeskWidgetSettingsConfig) => {
      updateZendeskWidgetSettings(settings);
    },
    [isZeReady] // should trigger update on ZD widget ready
  );

  const reset = useCallback(() => {
    updateZendeskWidgetSettings(defaultZendeskWidgetSettings);
  }, []);

  return {
    update,
    reset,
    show: useCallback(() => {
      try {
        // https://developer.zendesk.com/api-reference/widget-messaging/web/core/#show
        window.zE?.('messenger', 'show');
      } catch (err) {
        window.zE?.show();
      }
    }, []),
    hide: useCallback(() => {
      try {
        // https://developer.zendesk.com/api-reference/widget-messaging/web/core/#hide
        window.zE?.('messenger', 'hide');
      } catch (err) {
        window.zE?.hide();
      }
    }, []),
  };
}

export const ZendeskWidgetSettings = ({
  resetOnUnmount,
  ...settings
}: ZendeskWidgetSettingsConfig & { resetOnUnmount?: boolean }) => {
  const { isZeReady } = useZendeskWidgetCtx();
  const { reset, update } = useZendeskWidgetSettings();

  useEffect(() => {
    update(settings);
  }, [isZeReady]);

  useEffect(() => {
    return () => {
      if (resetOnUnmount) {
        reset();
      }
    };
  }, []);

  return null;
};

export const openZendeskWidget = () => {
  sendZendeskEvent({
    event: ['messenger', 'open'],
    fallback: ['webWidget', 'open'],
  });
};

import { PropsWithChildren, useEffect, useState } from 'react';
import breakpoints from '../breakpoints.cjs';

type Props =
  | {
      show: keyof typeof breakpoints;
      hide?: never;
    }
  | {
      show?: never;
      hide: keyof typeof breakpoints;
    };

export function Media({ children, show, hide }: PropsWithChildren<Props>) {
  const [matched, setMatched] = useState(true);

  const value = show ?? hide;
  if ((show && hide) || !value) {
    throw new Error(
      `<Media>: invalid props combination provided, either "show" or "hide" props must be provided, NOT both`
    );
  }

  useEffect(() => {
    const mql = window.matchMedia(`(min-width: ${breakpoints[value]}px)`);
    // initial check on hydrated
    setMatched(mql.matches);

    // attach listeners to cover change event
    function onChange(e: MediaQueryListEvent) {
      setMatched(e.matches);
    }
    mql.addEventListener('change', onChange, { passive: true });

    // remove listenter
    return () => mql.removeEventListener('change', onChange);
  }, [value]);

  // if `show` props provided, meaning we want to "show on match",
  // hence rendering children
  if (show) {
    return <>{matched ? children : null}</>;
  }

  // else `hide` props provided, meaning we want to "hide on match",
  // hence rendering null
  return <>{matched ? null : children}</>;
}

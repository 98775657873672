import {
  Button,
  TrashIcon,
  DotVerticalIcon,
  IconButton,
  ActionSheet,
  Loader,
} from '@setel/web-ui';
import { Link, NavigationHeader } from '~/components';
import { DateTime } from 'luxon';
import { useState, useEffect } from 'react';
import { ConfirmDeleteModal } from './ConfirmDeleteModal';
import { IconMenu } from './IconMenu';
import { IconMenuItem } from './IconMenuItem';
import { IconMenuItems } from './IconMenuItems';
import { useGetMessage } from './useInboxMessages';
import { useNavigate } from '@remix-run/react';

export const ViewMessage = () => {
  const navigate = useNavigate();
  const { data: message, isLoading, deleteMessage } = useGetMessage();
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [isActionSheetVisible, setIsActionSheetVisible] = useState(false);

  useEffect(() => {
    if (!isLoading && !message) {
      navigate('/', { replace: true });
    }
  }, [isLoading, message, navigate]);

  const MessageDateTime = () => (
    <>
      {message?.created && (
        <div className="text-sm leading-5 text-gray-light mt-5 md:mt-2">
          {DateTime.fromISO(message.created.toISOString()).toFormat(
            'dd LLL yyyy, hh:mm a'
          )}
        </div>
      )}
    </>
  );

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen-2/3">
        <Loader type="spinner-blue" className="" />
      </div>
    );
  }

  return message ? (
    <>
      {/* Mobile view menu */}
      <div className="block md:hidden">
        <NavigationHeader
          onBack={() => navigate(-1)}
          rightIcon={
            <div className="w-6 h-6">
              <IconButton
                className="w-6 h-6"
                onClick={() => setIsActionSheetVisible(true)}
              >
                <DotVerticalIcon />
              </IconButton>
              <ActionSheet
                isOpen={isActionSheetVisible}
                onDismiss={() => setIsActionSheetVisible(false)}
                cancelButtonText="cancel"
              >
                <ActionSheet.Content>
                  <ActionSheet.Item
                    destructiveAction
                    leftIcon={<TrashIcon />}
                    onClick={() => {
                      setIsActionSheetVisible(false);
                      setIsConfirmVisible(true);
                    }}
                  >
                    Delete message
                  </ActionSheet.Item>
                </ActionSheet.Content>
              </ActionSheet>
            </div>
          }
          children=""
        />
      </div>

      <div className="bg-gray-100">
        <div className="md:w-[580px] mx-auto md:mt-10 py-8 md:py-6 md:mb-32 md:bg-white md:shadow md:rounded-xl">
          <div className="px-5">
            <div className="flex justify-between justify-items-start">
              <div className="text-xl md:text-article-subtitle text-gray-dark font-medium leading-7 md:leading-9 w-11/12">
                {message.title}
              </div>
              <div className="w-6 h-6 hidden md:block">
                {/* Desktop context menu */}
                <IconMenu aria-label="Message Actions">
                  <IconMenuItems>
                    <IconMenuItem
                      onSelect={() => setIsConfirmVisible(true)}
                      className="text-error"
                    >
                      <TrashIcon /> Delete message
                    </IconMenuItem>
                  </IconMenuItems>
                </IconMenu>
              </div>
            </div>
            <div className="hidden md:block">
              <MessageDateTime />
            </div>
          </div>

          <div className="my-4 md:my-6 md:px-5 md:w-4/5">
            <img
              alt="Message"
              src={message.imageUrl}
              className="w-full md:rounded-lg"
            />
          </div>

          <div className="px-5">
            <p className="w-full whitespace-pre-wrap text-regular md:text-base text-gray-dark leading-5 mt-0 md:mt-6">
              {
                // This can include newlines so make sure `whitespace: pre-wrap` style is applied
                message.description
              }
            </p>
            <div className="block md:hidden">
              <MessageDateTime />
            </div>
            {message.url && (
              <div className="w-full mt-5 md:mt-6">
                <Link href={message.url}>
                  <Button className="w-full md:w-3/6" size="large">
                    {message.linkText || 'Learn more'}
                  </Button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <ConfirmDeleteModal
        header="Delete message?"
        body="Once you delete this message in your inbox, you won’t be
        able to undo it."
        isOpen={isConfirmVisible}
        onClose={() => setIsConfirmVisible(false)}
        onConfirm={() => {
          if (message) deleteMessage(message);
          setIsConfirmVisible(false);
        }}
      />
    </>
  ) : null;
};

import cn from 'classnames';
import { forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import { After, Before } from './Extra';

export const classes = [
  'shadow-sm',
  'appearance-none',
  'bg-none',
  'border',
  'rounded',
  'w-full',
  'py-3',
  'px-3',
  'text-gray-800',
  'focus:outline-none',
  'focus:shadow-focus',
  'caret-brand',
  'disabled:cursor-not-allowed',
];

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  error?: string;
  before?: ReactNode;
  after?: ReactNode;
  inputClass?: string | string[];
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ error, before, after, className, inputClass, ...props }, ref) => {
    return (
      <div className={cn('relative', className)}>
        {before && <Before>{before}</Before>}
        {after && <After>{after}</After>}
        <input
          ref={ref}
          {...props}
          className={cn(
            classes,
            {
              'border-error': error,
              'pl-10': before,
            },
            inputClass
          )}
        />
      </div>
    );
  }
);

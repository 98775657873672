import { BareButton } from '@setel/web-ui';
import { useVariables } from '~/domain/variable';
import { sendCreateSessionOtp } from '../../../domain/auth/auth.service';
import { ResendDelay } from '../hooks/useResendDelay';

type ResendOtpProps = {
  phone: string;
  resendDelay: ResendDelay;
  disabled?: boolean;
};

export const ResendOtp = ({ phone, disabled, resendDelay }: ResendOtpProps) => {
  const { data: variables } = useVariables();

  const resend = (data: { resend?: boolean; voice?: boolean }) => {
    sendCreateSessionOtp(
      { phone, ...data },
      { legacyDropInApi: !!variables?.web_prefer_legacy_dropin_api?.value }
    );
    resendDelay.reset();
  };

  if (resendDelay.left > 0)
    return (
      <p className="text-gray-light text-regular h-12">
        Request for a new code in {resendDelay.left} seconds
      </p>
    );

  return (
    <div className="h-12">
      <p className="text-gray-light text-regular">Didn't receive your code?</p>
      <div className="flex items-center mt-1">
        <BareButton disabled={disabled} onClick={() => resend({ voice: true })}>
          Call Me
        </BareButton>
        <span className="text-gray-light mx-4">|</span>
        <BareButton
          disabled={disabled}
          onClick={() => resend({ resend: true })}
        >
          Resend via SMS
        </BareButton>
      </div>
    </div>
  );
};

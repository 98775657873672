import { noop, useId } from '@setel/web-ui';
import { useRef, useEffect } from 'react';
import { runTimeConfig as config } from '~/shared/runtime-config';
import { Script } from '../Script';

export type WebMotorInsuranceProps = {
  path: string;
};

export const WebMotorInsurance: React.FC<
  React.PropsWithChildren<WebMotorInsuranceProps>
> = ({ path }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const hasInitRef = useRef(false);
  const cleanupRef = useRef(noop);

  const initWebMotorInsurance = (target: HTMLDivElement) => {
    if (
      hasInitRef.current === false &&
      typeof (window as any).initWebMotorInsurance === 'function'
    ) {
      hasInitRef.current = true;
      const result = (window as any).initWebMotorInsurance(
        target,
        path,
        config.MINI_APP.WEB_MOTOR_INSURANCE
      );
      cleanupRef.current = result;
    }
  };

  useEffect(() => {
    initWebMotorInsurance(divRef.current as HTMLDivElement);

    return () => {
      cleanupRef.current();
    };
  }, []);

  return (
    <div>
      <Script
        id={`web-motor-insurance-${useId()}`}
        src={normalizeManifestUrl(config.MINI_APP.WEB_MOTOR_INSURANCE)}
        onLoad={() => initWebMotorInsurance(divRef.current as HTMLDivElement)}
      />
      <div ref={divRef}></div>
    </div>
  );
};

function normalizeManifestUrl(publicPath: string) {
  if (publicPath?.split('/').pop() !== '') {
    return `${publicPath}/index.js`;
  }
  return `${publicPath}index.js`;
}

import { matchPath } from '@remix-run/react';
import type { NavLinkPropsWithLabel } from '~/components';

export enum RootNavigationRoute {
  HOME = '/',
  STATION_FINDER = '/locations',
  REWARDS = '/mesrarewards',
  PROFILE = '/profile',
  DEAL = '/deal-detail/:dealName/:dealId',
  CATALOGUE = '/deal-catalogue/:catalogueName/:catalogueId',
  INBOX_VIEW_MESSAGE = '/message/:messageId',
  MESRA_CARD_REGISTER_LANDING_PAGE = '/mesrarewardsregister',
}

export const TOP_NAV_LINKS: NavLinkPropsWithLabel[] = [
  {
    href: RootNavigationRoute.REWARDS,
    label: 'Mesra Rewards',
    icon: 'reward',
    isActive: (pathname) => {
      return (
        !!matchPath(RootNavigationRoute.REWARDS, pathname) ||
        !!matchPath(RootNavigationRoute.DEAL, pathname) ||
        !!matchPath(RootNavigationRoute.CATALOGUE, pathname)
      );
    },
    activeClassName: 'text-brand',
  },
  /* {
   *   href: RootNavigationRoute.STATION_FINDER,
   *   label: 'Locations',
   *   icon: 'pump',
   *   activeClassName: 'text-brand',
   * }, */
  {
    href: 'https://www.setel.com/PETRONAS-Shop?utm_medium=setelweb',
    label: 'PETRONAS Shop',
    target: '_blank',
  },
];

import type appboy from '@braze/web-sdk';
import { InboxMessage } from './InboxMessage';

type InboxContentProps = {
  messages: appboy.ClassicCard[];
  onClose: () => void;
};

export const InboxContent = ({ messages, onClose }: InboxContentProps) => (
  <div className="overflow-hidden rounded-b-[1rem]">
    <div className="flex-1 overflow-auto relative min-h-[40vh] max-h-[70vh]">
      {messages.length === 0 && (
        <div className="text-center text-gray-light mt-4 px-5 py-4">
          Your inbox is empty
        </div>
      )}
      {messages.map((message) => (
        <InboxMessage key={message.id} message={message} onClose={onClose} />
      ))}
    </div>
  </div>
);

import { ActionSheet, hasUtilClass } from '@setel/web-ui';
import { Icon, IconProp } from '~/components';
import { Size } from '~/components/theme';
import { ComponentProps, forwardRef } from 'react';
import cx from 'classnames';

export type MobileMenuItemProps = ComponentProps<typeof ActionSheet['Item']> & {
  leftIcon?: IconProp['name'];
  leftIconUrl?: IconProp['src'];
};

export const MobileMenuItem = forwardRef<
  HTMLButtonElement,
  MobileMenuItemProps
>(({ leftIcon, leftIconUrl, className, ...props }, ref) => {
  return (
    <ActionSheet.Item
      {...props}
      leftIcon={
        leftIconUrl ? (
          <Icon src={leftIconUrl} size={Size.BASE} />
        ) : (
          leftIcon && <Icon name={leftIcon} size={Size.BASE} />
        )
      }
      className={cx(!hasUtilClass(className, 'px-') && 'px-5', className)}
      ref={ref}
    />
  );
});

import { FC } from 'react';
import cn from 'classnames';
import Linkify from '~/components/Linkify';
import { Link } from '../Link';

export type InfoSectionProps = {
  title: string;
  info: string | string[];
  className?: string;
};

export const InfoSection: FC<React.PropsWithChildren<InfoSectionProps>> = ({
  title,
  info,
  className,
}) => (
  <div className={cn('mt-8', className)}>
    <p className="text-lg font-medium text-gray-dark">{title}</p>
    <Linkify
      componentDecorator={(href, _, key) => (
        <Link key={key} href={href}>
          {href}
        </Link>
      )}
    >
      <div
        className={cn(
          'mt-4 pl-1 leading-relaxed md:leading-normal',
          'text-regular md:text-base text-gray-dark'
        )}
      >
        {Array.isArray(info) ? (
          <ul className="px-6 list-decimal">
            {info.map((line, idx) => (
              <li className="pl-2" key={idx}>
                {line}
              </li>
            ))}
          </ul>
        ) : (
          <p>{info}</p>
        )}
      </div>
    </Linkify>
  </div>
);

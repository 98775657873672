import * as React from 'react';
import type { ReactNode } from 'react';
import { IconProp, Icon } from '../Icon';
import type { MenuItem } from '~/domain/wordpress/menu.type';
import cn from 'classnames';

export interface MegaMenuItem
  extends Pick<Partial<MenuItem>, 'url' | 'icon_url' | 'target'> {
  title: ReactNode;
  icon?: IconProp['name'];
  items?: MegaMenuItem[];
  external?: boolean;
  description?: string;
}

type MenuSize = 'base' | 'sm';

export interface MenuItemsListProps {
  items: MegaMenuItem[];
  className?: string;
  size?: MenuSize;
}

export interface NestedNavMenuProps extends MenuItemsListProps {}

export function NestedNavMenu({
  items,
  className = '',
  size = 'base',
}: NestedNavMenuProps) {
  const hasItems = items.some((it) => it.items?.length);

  const { itemsByTitle, itemsWithParent } = React.useMemo(() => {
    // List item group by first item have title and next items
    let orderedItems = [];
    let itemsWithParent = [];
    for (const item of items) {
      if (item.title && item.title !== ' ' && item.title !== ' ') {
        itemsWithParent.push(item);
        orderedItems.push([item]);
      } else {
        orderedItems[orderedItems.length - 1].push(item);
      }
    }
    return { itemsByTitle: orderedItems, itemsWithParent };
  }, [items]);

  const containerRef = React.useRef<React.ElementRef<'div'>>(null);

  // hack to overrides color of popover arrow
  React.useEffect(() => {
    const megaMenu = containerRef.current;
    if (!megaMenu) return;

    const setelPopoverArrow =
      megaMenu.previousElementSibling ||
      megaMenu.parentElement?.previousElementSibling;
    if (
      setelPopoverArrow &&
      setelPopoverArrow.hasAttribute('data-setel-popover-arrow')
    ) {
      setelPopoverArrow.classList.add('after:!bg-gray-super-light');
    }
  }, []);

  const [selectedTabIdx, setSelectedTabIdx] = React.useState(0);
  const id = React.useId();

  return (
    <>
      <div className={`${className} xl:flex`} ref={containerRef}>
        <div
          role="tablist"
          aria-label="Setel features tabs"
          className="sticky xl:static top-[-1px] xl:w-[260px] bg-gray-super-light px-[20px] py-[12px] xl:p-[20px] xl:rounded-l-[16px] flex xl:flex-col justify-center xl:justify-start mx-auto h-[72px] xl:h-auto"
        >
          {itemsWithParent.map((p, i) => (
            <div key={i}>
              <input
                type="radio"
                name="tab"
                aria-hidden
                className="hidden"
                tabIndex={-1}
                id={`tab-input-${id}-${i}`}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedTabIdx(i);
                  }
                }}
              />
              <label
                role="tab"
                aria-selected={i === selectedTabIdx}
                aria-label={typeof p.title === 'string' ? p.title : ''}
                aria-controls={`tab-panel-${id}-${i}`}
                id={`tab-label-${id}-${i}`}
                htmlFor={`tab-input-${id}-${i}`}
                className={cn(
                  `py-[12px] px-[24px] xl:p-[16px] rounded-[50px] xl:rounded-[8px] w-full flex justify-center items-center xl:flex-col xl:justify-start xl:items-start h-full cursor-pointer`,
                  i === selectedTabIdx && 'bg-white title-black'
                )}
              >
                {p.icon_url ? (
                  <img
                    src={p.icon_url}
                    alt=""
                    decoding="async"
                    loading="lazy"
                    className="flex-none w-[24px] h-[24px] m-2 xl:m-0"
                  />
                ) : (
                  <span
                    role="presentation"
                    className="flex-none w-[24px] h-[24px] bg-gray-200 rounded-full m-2 xl:m-0"
                  />
                )}
                {typeof p.title === 'string' ? (
                  <p className="text-[#2D333A] mt-[4px] xl:my-2 text-[18px]">
                    {p.title}
                  </p>
                ) : (
                  p.title
                )}
                {p.description && (
                  <p className="text-sm text-[#525B65] font-normal hidden xl:block">
                    {p.description}
                  </p>
                )}
              </label>
            </div>
          ))}
        </div>
        {hasItems ? (
          itemsByTitle.map((_, i) => (
            <div
              className={cn(
                i === selectedTabIdx ? 'xl:grid' : 'hidden',
                'xl:gap-[12px] xl:grid-flow-col xl:p-3'
              )}
              role="tabpanel"
              tabIndex={-1}
              aria-labelledby={`tab-label-${id}-${i}`}
              id={`tab-panel-${id}-${i}`}
              key={i}
              hidden={i !== selectedTabIdx}
            >
              {itemsByTitle[i].map(({ items }, ii) => (
                <div key={ii} className="xl:w-[360px]">
                  <MenuItemsList size={size} items={items!} />
                </div>
              ))}
            </div>
          ))
        ) : (
          <div className="xl:w-[360px]">
            <MenuItemsList size={size} items={items} />
          </div>
        )}
      </div>
    </>
  );
}

export function MenuItemsList({ items, size = 'base' }: MenuItemsListProps) {
  return (
    <ul>
      {items.map((item, i) => (
        <li key={i}>
          <a
            className={cn(
              'flex items-start text-gray-dark gap-[12px]',
              size === 'base' &&
                'xl:space-x-2 xl:px-2 py-3 space-x-3 px-5 hover:bg-gray-super-light xl:hover:rounded-lg',
              size === 'sm' && 'xl:p-3 space-x-3 px-5 py-3'
            )}
            href={item.url}
            target={item.target}
            {...(item.external && {
              target: '_blank',
              rel: 'noopener noreferrer',
            })}
          >
            {item.icon_url ? (
              <img
                src={item.icon_url}
                alt=""
                decoding="async"
                loading="lazy"
                className="flex-none w-[50px] h-[50px]"
              />
            ) : item.icon ? (
              <Icon name={item.icon} className="flex-none text-gray-light" />
            ) : (
              <span
                className="flex-none w-[50px] h-[50px] bg-gray-200 rounded-full"
                role="presentation"
              />
            )}
            <div>
              <p className="font-medium text-[15px] leading-[21px] text-[#2D333A]">
                {item.title}
              </p>
              <p className="text-sm text-[#525B65] font-normal">
                {item.description}
              </p>
            </div>
          </a>
        </li>
      ))}
    </ul>
  );
}

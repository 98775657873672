export function ModalGoogleReCaptchaHint() {
  return (
    <p className="text-center text-xs text-gray-light">
      This site is protected by reCAPTCHA and the Google{' '}
      <a
        className="text-brand"
        href="https://policies.google.com/privacy"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>{' '}
      and{' '}
      <a
        className="text-brand"
        href="https://policies.google.com/terms"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Service
      </a>{' '}
      apply.
    </p>
  );
}

import { useNavigate } from '@remix-run/react';
import { useWindowHistory } from './use-window-history';

export const useGoBackOrDefault = (url: string) => {
  const history = useWindowHistory();
  const navigate = useNavigate();

  return () =>
    // even though this does not guarantee that previous page is this site,
    // but this is the best we can do.
    history && history.length > 1
      ? navigate(-1)
      : navigate(url, { replace: true });
};

import cn from 'classnames';
import { Icon } from '~/components';
import { PlayerState } from './types';
import { usePlayer } from './context';
import { useInterval, formatDate, noop } from '@setel/web-ui';
import { useUpdateEffect } from '@reach/utils';
import { useState } from 'react';

interface ControlsProps {
  className?: string;
  visible?: boolean;
  onClick?: () => void;
  onPlayClick?: () => void;
  onPrevClick?: () => void;
  onNextClick?: () => void;
}

const styles = {
  controls: { button: `inline-block text-white` },
};

export function Controls({
  visible,
  onClick = noop,
  onPlayClick = noop,
  onPrevClick = noop,
  onNextClick = noop,
}: ControlsProps) {
  const { state, player, canNext, canPrev, fullScreen } = usePlayer();

  return (
    <div
      className={cn([
        'text-center my-auto space-x-[60px]',
        'focus:outline-none',
        { hidden: !visible },
      ])}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      {fullScreen && (
        <button
          type="button"
          className={cn(styles.controls.button, 'disabled:opacity-0')}
          onClick={onPrevClick}
          disabled={!canPrev}
        >
          <Icon
            className="w-14 h-14"
            name="player_prev"
            aria-label="Previous"
          />
        </button>
      )}
      {state === PlayerState.PAUSED && (
        <button
          type="button"
          className={cn(styles.controls.button)}
          onClick={(e) => {
            onPlayClick();
          }}
        >
          <Icon className="w-14 h-14" name="player_play" aria-label="Play" />
        </button>
      )}
      {state === PlayerState.PLAYING && (
        <button
          type="button"
          className={cn(styles.controls.button)}
          onClick={(e) => {
            player.pauseVideo();
          }}
        >
          <Icon className="w-14 h-14" name="player_pause" aria-label="Pause" />
        </button>
      )}
      {fullScreen && (
        <button
          type="button"
          className={cn(styles.controls.button, 'disabled:opacity-0')}
          onClick={onNextClick}
          disabled={!canNext}
        >
          <Icon className="w-14 h-14" name="player_next" aria-label="Next" />
        </button>
      )}
    </div>
  );
}

export function BottomOverlay({
  className,
  visible,
  onClick = noop,
}: ControlsProps) {
  const { player, state } = usePlayer();
  const [currentTime, setCurrentTime] = useState(0);

  const reset = useInterval(
    () => {
      setCurrentTime(player.getCurrentTime());
    },
    // tick while in PLAYING state, otherwise pause the interval.
    // setting interval to 500ms so that we can catch up early when out-of-sync
    // with player's actual .getCurrentTime()
    state === PlayerState.PLAYING ? 500 : null
  );

  useUpdateEffect(() => {
    if (state === PlayerState.ENDED) {
      reset();
    }
  }, [state]);

  // since currentTime state changes frequently, duration will also get re-calculated
  // so no need to make it a state
  const duration = Math.floor(player?.getDuration?.());

  return (
    <div
      className={cn([
        'absolute bottom-0 left-0 w-full bg-black bg-opacity-50 px-4 pt-4',
        'focus:outline-none',
        'pb-[max(1.25rem,calc(2*env(safe-area-inset-bottom)))]',
        { hidden: !visible },
        className,
      ])}
      tabIndex={-1}
      role="button"
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <div className="flex items-center justify-between">
        {duration ? (
          <span className={cn('text-sm text-white-off', className)}>
            {formatVideoDuration(currentTime)} / {formatVideoDuration(duration)}
          </span>
        ) : null}

        {/* break-out so it's easier to click */}
        <div className="-ml-6 -m-3">
          <Minimize className="pl-6 p-3" />
        </div>
      </div>

      {duration ? (
        <Progress
          value={Math.floor((currentTime / duration) * 100)}
          bufferedPercent={Math.floor(player?.getVideoLoadedFraction() * 100)}
          className="mt-5"
        />
      ) : null}
    </div>
  );
}

export function Minimize({ className }: ControlsProps) {
  const { player, setShowFullScreen } = usePlayer();

  return (
    <button
      tabIndex={-1}
      type="button"
      className={cn(styles.controls.button, className)}
      onClick={(e) => {
        setShowFullScreen(false);
        player.pauseVideo();
      }}
    >
      <Icon
        name="player_minimize"
        aria-label="Exit fullscreen"
        className="w-6"
      />
    </button>
  );
}

export function Progress({
  value,
  bufferedPercent,
  className,
}: {
  value: number;
  bufferedPercent: number;
  className?: string;
}) {
  return (
    <div
      className={cn(className, 'relative h-[3px] bg-white bg-opacity-[0.35]')}
    >
      <div
        role="progressbar"
        aria-valuenow={bufferedPercent}
        aria-valuemin={0}
        aria-valuemax={100}
        aria-valuetext={`Video buffered ${bufferedPercent} percent`}
        className="absolute top-0 left-0 h-full bg-white bg-opacity-[0.75]"
        style={{ width: `${bufferedPercent}%` }}
      ></div>
      <div
        role="progressbar"
        aria-valuenow={value}
        aria-valuemin={0}
        aria-valuemax={100}
        aria-valuetext={`Video played ${value} percent`}
        className="absolute top-0 left-0 h-full bg-brand"
        style={{ width: `${value}%` }}
      ></div>
    </div>
  );
}

function formatVideoDuration(seconds: number) {
  const date = new Date(0, 0, 0, 0, 0, seconds, 0);
  return formatDate(date, { format: 'm:ss' });
}

import * as React from 'react';

export const useWindowHistory = () => {
  const [history, setHistory] = React.useState<History | undefined>(undefined);

  React.useEffect(() => {
    if (window && window.history) {
      setHistory(window.history);
    }
  }, []);

  return history;
};

import { ArrowLeftIcon } from '@setel/web-ui';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { useGoBackOrDefault } from '~/shared/hooks/useGoBackOrDefault';
import { IconButton } from '../Button';

type NavigationHeaderProps = {
  children: ReactNode;
  onBack?: () => void;
  rightIcon?: ReactNode;
  className?: string;
};
export const NavigationHeader = ({
  children,
  onBack,
  rightIcon,
  className,
}: NavigationHeaderProps) => {
  const defaultBack = useGoBackOrDefault('');
  return (
    <div
      className={classNames(
        'flex justify-between items-center px-5 py-4 border-b text-gray-dark shadow md:shadow-none',
        className
      )}
    >
      <IconButton
        onClick={onBack || defaultBack}
        className="md:hidden"
        children={<ArrowLeftIcon />}
      />
      <div>{children}</div>
      <div className="w-6 h-6">{rightIcon}</div>
    </div>
  );
};

module.exports = {
  xs: 375,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
  // 1740 breakpoint is defined in `require('@setel/web-ui/tailwind.config').theme.extend.screens`
  // so need to re-define it here too
  '3xl': 1740,
};

import { useState, useEffect, useCallback } from 'react';

const RESEND_DELAY = 60;

export type ResendDelay = {
  left: number;
  setLeft(left: number): void;
  reset(): void;
};

export const useResendDelay = (): ResendDelay => {
  const [left, setLeft] = useState(0);
  const reset = useCallback(() => setLeft(RESEND_DELAY), []);
  const execute = left > 0;
  useEffect(() => {
    if (!execute) return;
    const handler = setInterval(() => setLeft((val) => val - 1), 1000);
    return () => clearInterval(handler);
  }, [setLeft, execute]);

  return { left, setLeft, reset };
};

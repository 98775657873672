import { Icon, MobileMenuItem, NavLink } from '~/components';
import { useNavItemsHook } from '../';
import {
  MenuItemsListProps,
  NestedNavMenu,
} from '~/components/MegaMenu/MegaMenu';
import { useMemo } from 'react';

export interface MenuProps {
  onParentNavItemClick?: (item: MenuItemsListProps) => void;
}

export const Menu = () => {
  const { data } = useNavItemsHook({ suspense: true });

  // for mobile, move nested (mega) menu item to last
  const menuItems = useMemo(() => {
    let nestedMenuItem: (typeof data)[number] | undefined = undefined;
    let flatMenu: typeof data = [];
    for (const menu of data) {
      if (menu.items && menu.items.length > 0) {
        nestedMenuItem = menu;
      } else {
        flatMenu.push(menu);
      }
    }
    if (nestedMenuItem) flatMenu.push(nestedMenuItem);
    return flatMenu;
  }, [data]);

  return (
    <>
      {menuItems.map((item, i) => {
        const { href: to, label, icon, iconUrl, items, ...props } = item;
        return items && Boolean(items?.length) ? (
          <div key={i}>
            <NestedNavMenu
              items={items}
              size={item.size}
              className="-mt-2 pb-4"
            />
          </div>
        ) : (
          <NavLink
            {...props}
            key={i}
            className="block"
            href={to}
            tabIndex={-1}
            activeClassName="border-transparent"
          >
            <MobileMenuItem
              leftIcon={icon}
              leftIconUrl={iconUrl}
              children={label}
            />
          </NavLink>
        );
      })}
    </>
  );
};

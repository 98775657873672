export const extractMessageId = (messageId: string | undefined) => {
  if (!messageId) return null;

  const decodedMessageId = atob(messageId);
  const queryParams = decodedMessageId && decodedMessageId.split('&');

  if (queryParams && queryParams.length) {
    const firstEle = queryParams.shift();
    const finalId = firstEle?.split('=');
    return finalId && finalId.length && finalId[1];
  }

  return null;
};

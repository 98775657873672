import cn from 'classnames';
import { FC, AriaAttributes } from 'react';
import { Size } from '../theme';
import { ICON_SET } from './IconSet';

export const SIZE_STYLE: Record<Size, string> = {
  [Size.BIG]: 'w-8 h-8',
  [Size.BASE]: 'w-6 h-6',
  [Size.SMALL]: 'w-4 h-4',
};

export type IconProp = (
  | {
      name: keyof typeof ICON_SET;
      src?: never;
    }
  | {
      name?: never;
      src: string;
    }
) & {
  size?: Size;
  className?: string;
  onClick?: () => void;
} & AriaAttributes;

export const Icon: FC<React.PropsWithChildren<IconProp>> = ({
  size = Size.BASE,
  name,
  src,
  className,
  ...props
}) => {
  if (src) {
    return (
      <img src={src} className={cn(className, SIZE_STYLE[size])} {...props} />
    );
  }

  const I = ICON_SET[name!];
  return <I className={cn(className, SIZE_STYLE[size])} {...props} />;
};

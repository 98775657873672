export enum ProfileRoute {
  INDEX = '/profile',
  ACCOUNT_INFO = '/profile/account-info',
  MESRA_CARD = '/profile/mesra-card',
  MESRA_HISTORY = '/profile/mesra-transaction-history',
  MESRA_HISTORY_EARNINGS = '/profile/mesra-transaction-detail/earnings/:id',
  MESRA_HISTORY_REDEMPTION = '/profile/mesra-transaction-detail/redemption/:id',
  VOUCHERS = '/profile/voucher',
  VOUCHER_DETAILS = '/profile/voucher/:voucherId',
  BADGES = '/profile/badge',
  SUBMIT_RECEIPT = '/profile/submit-receipt',
  NOTIFICATIONS = '/profile/notification-settings',
  SECURITY = '/profile/security',
  SESSIONS = '/profile/sessions',
  // PAYMENT_HISTORY = '/profile/payment-history/payment-detail/'
  // RECEIPT_DETAILS = '/profile/payment-history/receipt-details/<payment-receipt-display-name>' TBD: url must contains id
}

export enum IdentityTypes {
  ic_number = 'IC Number',
  old_ic_number = 'Old IC Number',
  passport_number = 'Passport Number',
}

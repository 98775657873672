import { forwardRef } from 'react';
import cn from 'classnames';
import { Button, ButtonProps } from '@setel/web-ui';

export type PetronasButtonProps = ButtonProps;

export const PetronasButton = forwardRef<
  HTMLButtonElement,
  PetronasButtonProps
>(({ className, variant = 'primary', ...props }, ref) => {
  return (
    <Button
      {...props}
      variant={variant}
      className={cn(
        !props.disabled &&
          {
            primary: [
              'border-petronas-brand',
              'bg-petronas-brand hover:bg-petronas-brand-600',
              'outline-none',
            ],
            outline: [
              'text-petronas-brand border-petronas-brand',
              'hover:text-petronas-brand hover:bg-petronas-brand-100',
              'outline-none',
            ],
            error: [],
            ['error-outline']: [],
          }[variant],
        className
      )}
      ref={ref}
    />
  );
});

import cn from 'classnames';
import { DownloadViaLink, DownloadViaSMS } from './';

type DownloadAppBannerProps = {
  className?: string;
};

export const DownloadAppBanner = ({ className }: DownloadAppBannerProps) => {
  return (
    <div className={cn(className, 'bg-[#ebf9ff]')}>
      <div className="hidden md:block container mx-auto py-8">
        <DownloadViaSMS />
      </div>
      <div className="container mx-auto py-8 md:hidden">
        <DownloadViaLink showDownloadLinks />
      </div>
    </div>
  );
};

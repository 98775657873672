import { GetAppButton, MobileMenuItem } from '~/components';
import { ActionSheet } from '@setel/web-ui';
import { destroySession } from '~/domain/auth/auth.service';
import { defaultOnError } from '~/domain/error';
import { InboxButton } from '~/features/Inbox';
import { ProfileMenu } from '~/features/Profile';
import { useAccountAttributes } from '~/features/Attributes';
import { useCallback } from 'react';

export const UserMenu = () => {
  const { accountAppInstall: appInstalled } = useAccountAttributes();
  const logout = useCallback(() => {
    destroySession().catch(defaultOnError);
  }, []);

  return (
    <div className="xl:py-2">
      <div className="xl:hidden">
        <InboxButton />
      </div>

      <ProfileMenu hideDivider />
      <ActionSheet.ItemDivider />
      {!appInstalled && <GetAppButton openMode="new-tab" />}
      <MobileMenuItem
        leftIcon="logout"
        destructiveAction
        onClick={logout}
        children="Logout"
      />
    </div>
  );
};

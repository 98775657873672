import cn from 'classnames';
import { TopNav } from '~/components';
import { AuthControls } from '~/features/Auth';
import { useTopNavVisibility } from './useTopNavVisibility';
import { ClientOnly } from 'remix-utils/client-only';

export const RootNavigation = () => {
  const isTopNavVisible = useTopNavVisibility();

  return (
    <>
      <TopNav
        className={cn('md:block sticky top-0 z-50 w-full', {
          hidden: isTopNavVisible,
        })}
      >
        <ClientOnly fallback={<div />}>{() => <AuthControls />}</ClientOnly>
      </TopNav>
    </>
  );
};

import { FC } from 'react';
import * as React from 'react';
import { Icon } from '../../Icon';

export interface MalaysiaFlagPrefixProps {
  phoneCode: string;
}
export const MalaysiaFlagPrefix: FC<
  React.PropsWithChildren<MalaysiaFlagPrefixProps>
> = ({ phoneCode }) => {
  return (
    <div className="flex justify-between items-center w-16">
      <Icon name="malaysia" aria-label="Malaysia flag" />
      <span className="text-gray-800">+{phoneCode}</span>
    </div>
  );
};

import { MobileMenuProps, MobileMenu } from '~/components';
import { UserMenu } from './UserMenu';

export interface UserMobileMenuProps
  extends Omit<MobileMenuProps, 'children'> {}

export function UserMobileMenu({ ...props }: UserMobileMenuProps) {
  return (
    <MobileMenu {...props} overlayClassName="xl:hidden">
      <UserMenu />
    </MobileMenu>
  );
}

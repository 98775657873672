import cn from 'classnames';
import { FC } from 'react';
import * as React from 'react';
import { Label } from './Label';

export interface FormControlProps {
  label?: string;
  error?: string;
  helperText?: string;
  inlineHelperText?: boolean;
  className?: string;
  htmlFor?: string;
}
export const FormControl: FC<React.PropsWithChildren<FormControlProps>> = ({
  label,
  htmlFor,
  error,
  helperText,
  inlineHelperText,
  className,
  children,
}) => {
  const hint = error || helperText;
  return (
    <div className={cn('w-full', className)}>
      {label && (
        <Label htmlFor={htmlFor} className="mb-1">
          {label}
        </Label>
      )}
      <div className={cn({ 'flex items-center': inlineHelperText })}>
        {children}
        <label
          htmlFor={htmlFor}
          className={cn(
            `${
              helperText ? 'h-10' : 'h-5 pt-0.5 text-[10px]'
            } text-xs block overflow-hidden`,
            {
              'ml-2 text-sx leading-[1.125rem]': inlineHelperText,
              'text-error': error,
              'text-[#525B65]': !error,
              'opacity-0': !hint,
            }
          )}
        >
          {hint}
        </label>
      </div>
    </div>
  );
};

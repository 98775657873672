import * as React from 'react';
import { useVisibility } from '~/shared';
import { VisibilityName, ZendeskTagsName } from '~/store';
import { Loader, Modal, ModalProps } from '@setel/web-ui';
import { useIsAuthorized } from './hooks';
import type { Slider as SlickSlider } from '~/components/Slick';
import { useZendeskTag } from '~/shared/hooks/useZendeskTag';
import { Slider } from '../../components/Slider';
import { AccountState, OtpVerification } from '../../domain/auth/auth.type';
import { useIntroduceYourselfForm } from './hooks/useIntroduceYourselfForm';
import { useSendOtpForm } from './hooks/useSendOtpForm';
import { useVerifyOtpForm } from './hooks/useVerifyOtpForm';
import { IntroduceYourself } from './IntroduceYourself';
import { SendOtp } from './SendOtp';
import { VerifyOtp } from './VerifyOtp';
import { ModalGoogleReCaptchaHint } from '~/components/ModalGoogleReCaptchaHint';

export const AuthModal = ({
  onDismiss,
}: Partial<Pick<ModalProps, 'onDismiss'>>) => {
  const modal = useVisibility(VisibilityName.AUTH);
  const isAuthorized = useIsAuthorized();

  const zendeskTag = useZendeskTag();

  const otpVerification = React.useRef<OtpVerification>({
    accountState: AccountState.CreateAccount,
    otpToken: '',
    phoneToken: '',
  });

  const [loginErrorMessage, setLoginErrorMessage] = React.useState<string>();
  const slider = React.useRef<SlickSlider>(null);

  const sendOtpForm = useSendOtpForm({
    goTo: () => {
      setLoginErrorMessage(undefined);
      slider.current?.slickNext();
    },
  });

  const verifyOtpForm = useVerifyOtpForm({
    goTo: (data: OtpVerification) => {
      slider.current?.slickNext();
      otpVerification.current = data;
    },
    phone: sendOtpForm.values.phone,
    goBack: (loginErrorMessage?: string) => {
      slider.current?.slickPrev();
      setLoginErrorMessage(loginErrorMessage);
    },
  });

  const introduceYourselfForm = useIntroduceYourselfForm({
    otpVerification: otpVerification.current,
    phone: sendOtpForm.values.phone,
  });

  const [currentIndex, setCurrentIndex] = React.useState(0);

  const onBack = () => {
    introduceYourselfForm.resetForm();
    verifyOtpForm.resetForm();
    setLoginErrorMessage(undefined);
    if (currentIndex === 2) {
      return slider.current?.slickGoTo(0);
    }
    slider.current?.slickPrev();
  };

  React.useEffect(() => {
    zendeskTag.set(
      (currentIndex === 1 && ZendeskTagsName.LOGIN_OTP_CONFIRM) ||
        (currentIndex === 2 && ZendeskTagsName.GET_USER_DETAILS) ||
        ZendeskTagsName.LOGIN_OTP_REQUEST
    );
    return () => {
      zendeskTag.reset();
    };
  }, [currentIndex, zendeskTag]);

  React.useEffect(() => {
    if (modal.isOpen) {
      setCurrentIndex(0);
      sendOtpForm.resetForm();
      verifyOtpForm.resetForm();
      introduceYourselfForm.resetForm();
    }
  }, [modal.isOpen]);

  return (
    <Modal
      aria-label="Login"
      noHeaderBorder
      isOpen={modal.isOpen && !isAuthorized}
      onDismiss={(e) => {
        modal.close();
        if (onDismiss) onDismiss(e);
      }}
      onBack={currentIndex !== 0 ? onBack : undefined}
    >
      <Modal.Header>
        {/* As per design, no title, but need nav buttons */}
      </Modal.Header>
      <Modal.Body>
        <React.Suspense
          fallback={<Loader type="spinner-blue" className="mx-auto" />}
        >
          <Slider
            ref={slider}
            adaptiveHeight
            swipe={false}
            arrows={false}
            infinite={false}
            pauseOnFocus
            className="login-modal-slider"
            beforeChange={(_prev, next) => setCurrentIndex(next)}
          >
            <SendOtp
              key="send-otp"
              formik={sendOtpForm}
              loginErrorMessage={loginErrorMessage}
            />
            <VerifyOtp
              key="verify-otp"
              currentIndex={currentIndex}
              formik={verifyOtpForm}
              phone={sendOtpForm.values.phone}
            />
            <IntroduceYourself
              key="introduce-yourself"
              formik={introduceYourselfForm}
            />
          </Slider>

          <div className="mt-6">
            <ModalGoogleReCaptchaHint />
          </div>
        </React.Suspense>
      </Modal.Body>
    </Modal>
  );
};

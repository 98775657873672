import { forwardRef, RefObject, useState } from 'react';
import type { Slider as SlickSlider } from '~/components/Slick';
import { Slider } from '../Slider';
import { GalleryImage, GalleryImageProps } from './GalleryImage';
import type { Image } from '~/shared';

export type GalleryPaginationProps = {
  images: Image[];
  imageComponent?: (props: GalleryImageProps) => JSX.Element;
  main: RefObject<SlickSlider>;
};

export const GALLERY_PAGINATION_SLIDES_TO_SHOW = 6;

export const GalleryPagination = forwardRef<
  SlickSlider,
  GalleryPaginationProps
>(({ images, main, imageComponent }, ref) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const ImgComp = imageComponent ?? GalleryImage;

  return (
    <div className="hidden md:block">
      <Slider
        className="full-height-slider pagination-slider"
        ref={ref}
        beforeChange={(_, next) => {
          main.current?.slickGoTo(next);
          setActiveIndex(next);
        }}
        variableWidth={images.length < GALLERY_PAGINATION_SLIDES_TO_SHOW}
        slidesToShow={Math.min(
          GALLERY_PAGINATION_SLIDES_TO_SHOW,
          images.length
        )}
        focusOnSelect
        arrows={false}
      >
        {images.map(({ url }, index) => (
          <div
            style={{
              width: `${100 / GALLERY_PAGINATION_SLIDES_TO_SHOW}%`,
            }}
            key={url}
          >
            <ImgComp isPagination isActive={index === activeIndex} url={url} />
          </div>
        ))}
      </Slider>
    </div>
  );
});

import { ComponentType, FC, SVGProps } from 'react';
import * as React from 'react';
import cn from 'classnames';

export type InfoProps = {
  title: string;
  subTitle: string;
  svg: ComponentType<React.PropsWithChildren<SVGProps<SVGSVGElement>>>;
  className?: string;
  largeTitle?: boolean;
};

export const Info: FC<React.PropsWithChildren<InfoProps>> = ({
  title,
  subTitle,
  svg: Svg,
  largeTitle,
  className,
}) => {
  return (
    <div className={cn('flex flex-col items-center my-4', className)}>
      <Svg className="mb-4" />
      <span
        className={cn('text-xl text-gray-dark text-center leading-tight mb-4', {
          'text-2xl font-medium': largeTitle,
        })}
      >
        {title}
      </span>
      <h4 className="text-regular text-gray-light text-center leading-snug">
        {subTitle}
      </h4>
    </div>
  );
};

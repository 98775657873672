import * as React from 'react';
import {
  VerifyOtp as VerifyOtpForm,
  VerifyOtpRef,
} from '~/features/Profile/Security/VerifyOtp';
import { useVerifyOtpForm } from '../hooks/useVerifyOtpForm';

type VerifyOtpProps = {
  formik: ReturnType<typeof useVerifyOtpForm>;
  phone: string;
  currentIndex: number;
  title?: string;
};

export const VerifyOtp = ({
  formik,
  phone,
  currentIndex,
  title,
}: VerifyOtpProps) => {
  const { handleSubmit, setFieldValue } = formik;
  const { error } = formik.getFieldMeta('otp');

  const otpInputRef = React.useRef<VerifyOtpRef>(null);

  React.useEffect(() => {
    if (currentIndex !== 1) return;
    // focus on otp input after Slider transition finished
    let interval = setTimeout(() => {
      otpInputRef.current?.clearAndFocus();
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [currentIndex]);

  React.useEffect(() => {
    if (error) {
      otpInputRef.current?.clearAndFocus();
    }
  }, [error]);

  const submitButtonRef = React.useRef<React.ElementRef<'button'>>(null);

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col items-center text-center"
    >
      <VerifyOtpForm
        ref={otpInputRef}
        phone={phone}
        submitting={formik.isSubmitting}
        isLoading={formik.isSubmitting}
        error={error}
        title={title}
        onOtpFilled={React.useCallback(
          async (value) => {
            await setFieldValue('otp', value, true);
            submitButtonRef.current!.click();
          },
          [setFieldValue]
        )}
      />
      <button
        role="submit"
        className="sr-only"
        tabIndex={-1}
        ref={submitButtonRef}
      >
        Submit OTP
      </button>
    </form>
  );
};

import { type AnchorHTMLAttributes, forwardRef } from 'react';
import cn from 'classnames';
import { type TextColor } from '../theme';

type LinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  color?: TextColor;
  underlineWhenHover?: boolean;
};

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      children,
      color = 'text-brand',
      target = '_blank',
      rel = target === '_blank' ? 'noopener noreferrer' : undefined,
      underlineWhenHover = false,
      className,
      ...props
    },
    ref
  ) => {
    return (
      <a
        {...props}
        ref={ref}
        target={target}
        rel={rel}
        className={cn(
          `${
            underlineWhenHover ? 'hover:underline hover:font-bold' : 'underline'
          } ${color}`,
          className
        )}
      >
        {children}
      </a>
    );
  }
);

enum Color {
  PETRONAS_BRAND = 'petronas-brand',
  BRAND = 'brand',
  TURQUOISE = 'turquoise',
  LEMON = 'lemon',
  PURPLE = 'purple',
  BLUE = 'blue',
  CARBON = 'carbon',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  WHITE = 'white',
  GREEN = 'green-500',
}

export type TextColor = `text-${Color}`;
export type BgColor = `bg-${Color}`;

export enum Size {
  BASE = 'base',
  SMALL = 'small',
  BIG = 'big',
}

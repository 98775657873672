import { Button, Dialog } from '@setel/web-ui';
import { DeleteButton } from '~/components';
import { useRef, useCallback } from 'react';

type ConfirmDeleteModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  header: string;
  body: string;
};

export const ConfirmDeleteModal = ({
  isOpen,
  onClose,
  onConfirm,
  header,
  body,
}: ConfirmDeleteModalProps) => {
  const onCloseStopPropagation = useCallback(
    (e: MouseEvent | KeyboardEvent) => {
      e.stopPropagation();
      onClose();
    },
    [onClose]
  ) as any;
  const cancelButtonRef = useRef(null);

  return (
    <Dialog
      isOpen={isOpen}
      onDismiss={onCloseStopPropagation}
      leastDestructiveRef={cancelButtonRef}
    >
      <Dialog.Content header={header}>{body}</Dialog.Content>
      <Dialog.Footer>
        <Button
          variant="outline"
          onClick={onCloseStopPropagation}
          ref={cancelButtonRef}
        >
          CANCEL
        </Button>
        <DeleteButton onClick={onConfirm}>DELETE</DeleteButton>
      </Dialog.Footer>
    </Dialog>
  );
};

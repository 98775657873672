import { InputHTMLAttributes, forwardRef } from 'react';

export type SelectSheetOptionProps = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  'id' | 'checked' | 'onChange'
> & {
  label?: string;
};

export const SelectSheetOption = forwardRef<
  HTMLInputElement,
  SelectSheetOptionProps
>(({ id, label, checked, onChange }, ref) => {
  const input = (
    <input
      ref={ref}
      id={id}
      name="sort"
      type="radio"
      className="form-radio h-6 w-6"
      checked={checked}
      onChange={onChange}
    />
  );

  return !label ? (
    input
  ) : (
    <label htmlFor={id} className="flex justify-between p-6">
      {label}
      {input}
    </label>
  );
});

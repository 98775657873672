import { FC } from 'react';
import * as React from 'react';
import { SelectProps } from './select.type';
import { SelectDropdown } from './SelectDropdown';
import { SelectSheet } from './SelectSheet';

export const Select: FC<React.PropsWithChildren<SelectProps>> = (props) => (
  <>
    <SelectDropdown {...props} />
    <SelectSheet {...props} />
  </>
);

import { useComposedRefs } from '@reach/utils';
import { OtpInput, noop, Loader } from '@setel/web-ui';
import * as React from 'react';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useResendDelay } from '~/features/Auth/hooks/useResendDelay';
import { ResendOtp } from '~/features/Auth/VerifyOtp';

interface Props {
  phone: string;
  onOtpFilled: (value: string) => void;
  error?: string;
  submitting?: boolean;
  isLoading?: boolean;
  autoFocus?: boolean;
  title?: string;
}

export type VerifyOtpRef = {
  clearAndFocus: () => void;
};

export const VerifyOtp = React.forwardRef<unknown, Props>(
  (
    {
      onOtpFilled = noop,
      phone,
      error,
      submitting,
      isLoading,
      autoFocus,
      title,
    },
    ref
  ) => {
    const otpId = React.useId();
    const { t } = useTranslation();

    const [otp, setOtp] = React.useState('');

    const otpInputRef = React.useRef<HTMLInputElement>(null);
    React.useImperativeHandle<unknown, VerifyOtpRef>(
      ref,
      () => ({
        clearAndFocus: () => {
          flushSync(() => {
            setOtp('');
          });
          otpInputRef.current?.focus();
        },
      }),
      []
    );

    const _refs = useComposedRefs(ref, otpInputRef);

    const resendDelay = useResendDelay();
    React.useEffect(() => resendDelay.reset(), [resendDelay.reset]);

    return (
      <>
        <img
          alt=""
          decoding="async"
          src="/assets/spot-verification.svg"
          className="h-20 w-20"
        />
        <div className="mt-5 mb-1 text-xl font-medium">
          {title || 'Enter verification code'}
        </div>
        <div className="mb-10 text-mediumgrey">
          {t('verifyOtp.enterDigitCodeDescription', {
            phone_number: `+${phone}`,
          })}
        </div>

        <div className="relative" aria-busy={isLoading || submitting}>
          <OtpInput
            ref={_refs}
            name="otp"
            id={`otp-${otpId}`}
            disabled={submitting}
            value={otp}
            onChangeValue={React.useCallback(
              (value: string) => {
                setOtp(value);
                if (value.length === 6) {
                  // Update <input>'s value synchronously so that we can submit outer <form>
                  // directly in `onOtpFilled` handler. Otherwise, React might defer DOM updates
                  // thus causes partial/missing value when using with <form>.
                  otpInputRef.current!.value = value;
                  onOtpFilled(value);
                }
              },
              [onOtpFilled]
            )}
            autoFocus={autoFocus}
            useWebOtp
            autoComplete="off"
            wrapperClass="whitespace-nowrap"
            borderClass={error && 'border-error-500'}
            focusBorderClass={
              error && 'border-error-500 ring ring-error-500 ring-opacity-40'
            }
            aria-invalid={Boolean(error)}
            aria-errormessage={`otp-error-${otpId}`}
          />
          {isLoading && (
            <div className="absolute top-1/2 -translate-y-1/2 w-full flex justify-center">
              <Loader type="spinner-blue" />
            </div>
          )}
        </div>
        {Boolean(error) && (
          <p
            id={`otp-error-${otpId}`}
            className="text-sm text-error-500 text-left w-full px-2 mt-2"
          >
            {error}
          </p>
        )}

        <div className="mt-6">
          <ResendOtp
            disabled={submitting}
            phone={phone}
            resendDelay={resendDelay}
          />
        </div>
      </>
    );
  }
);

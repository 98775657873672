import { FC } from 'react';
import * as React from 'react';
import { CSSTransition } from '../Transition';
import { AnimationProps } from '../transition.const';

export const FadeIn: FC<React.PropsWithChildren<AnimationProps>> = ({
  children,
  ...rest
}) => {
  return (
    <CSSTransition
      {...rest}
      enter="transition ease-out"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {children}
    </CSSTransition>
  );
};

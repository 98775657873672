import cn from 'classnames';
import { ImgHTMLAttributes } from 'react';

type ImageRatioProps = {
  x: number;
  y: number;
  url?: string;
  className?: string;
  wrapperClassName?: string;
  grayscale?: boolean;
  imgProps?: Omit<
    ImgHTMLAttributes<HTMLImageElement>,
    'src' | 'style' | 'className'
  >;
};

export const ImageRatio = ({
  url,
  x,
  y,
  className,
  wrapperClassName,
  grayscale,
  imgProps,
}: ImageRatioProps) => {
  const aspectRatio = (y / x) * 100;
  return (
    <div
      className={cn('relative', wrapperClassName)}
      style={{ paddingBottom: `${aspectRatio}%` }}
    >
      <img
        alt=""
        src={url}
        className={cn(
          'absolute w-full h-full bg-center bg-no-repeat bg-cover object-cover',
          className
        )}
        style={{
          ...(grayscale && { filter: 'grayscale(100%)' }),
          backgroundClip: 'content-box',
          backgroundImage: `url(/assets/thumbnail_dealscard.svg)`,
        }}
        {...imgProps}
      />
    </div>
  );
};

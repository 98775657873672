import { ReactNode } from 'react';
import cn from 'classnames';

type CardContentProps = {
  children: ReactNode;
  className?: string;
};

export const CardContent = ({ children, className }: CardContentProps) => (
  <div className={cn('px-2 py-3 md:px-4 md:py-4', className)}>{children}</div>
);

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useVariables } from '~/domain/variable';
import { phoneValidation } from '../../../components';
import { sendCreateSessionOtp } from '../../../domain/auth/auth.service';
import {
  onlyValidationError,
  defaultOnError,
  ValidationError,
  getApiErrorMessage,
} from '../../../domain/error';

export const useSendOtpForm = ({ goTo }: { goTo?: () => void }) => {
  const { data: variables } = useVariables();

  const handleValidationError = ({ fields }: ValidationError) => {
    formik.setErrors(fields);
  };

  const formik = useFormik({
    initialValues: { phone: '' },
    validateOnBlur: false,
    validationSchema: Yup.object({
      phone: phoneValidation,
    }),
    onSubmit: (data) =>
      sendCreateSessionOtp(data, {
        legacyDropInApi: !!variables?.web_prefer_legacy_dropin_api?.value,
      })
        .then(goTo)
        .catch(onlyValidationError(handleValidationError))
        .catch((err) => {
          formik.setFieldError('phone', getApiErrorMessage(err));
          defaultOnError(err);
        }),
  });

  return formik;
};

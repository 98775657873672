import { useTranslation } from 'react-i18next';
import {
  DownloadViaLink,
  DownloadViaSMSButton,
} from '~/features/DownloadAppBanner';
import getAppBanner from './get-app-banner.png';

export type GetAppModalProps = {};

export const GetAppModal = (_: GetAppModalProps) => {
  const { t } = useTranslation();

  return (
    <div className="h-auto flex gap-10 flex-nowrap place-items-stretch max-w-[900px]">
      <div className="">
        <img
          alt="get app"
          decoding="async"
          loading="lazy"
          width={450}
          height={496}
          src={getAppBanner}
        />
      </div>
      <div className="max-w-[384px] flex flex-col justify-end">
        <DownloadViaLink showDownloadLinks={false} />
        <div className="mt-10">
          <DownloadViaSMSButton
            buttonClass="w-full"
            label={t('downloadViaSMSForm')}
          />
        </div>
      </div>
    </div>
  );
};

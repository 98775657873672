import { useFormik } from 'formik';
import debounce from 'lodash-es/debounce';
import { useState } from 'react';
import * as Yup from 'yup';
import * as authService from '../../../domain/auth/auth.service';
import {
  CreateAccountParams,
  OtpVerification,
  PromoCodeParam,
} from '../../../domain/auth/auth.type';
import {
  ApiAxiosError,
  defaultOnError,
  onlyValidationError,
  ValidationError,
} from '../../../domain/error';
import { trackUserEvent } from '~/shared/analytics';
import { EVENT_TYPES } from '~/shared/analytics.events';
import { useVisibility, setUserIsNew } from '~/shared/hooks';
import { VisibilityName } from '~/store';
import { useQueryClient } from 'react-query';

const validationSchema = Yup.object({
  fullName: Yup.string()
    .required('Required')
    .min(3, 'Full name must be between 3 and 64 characters.')
    .max(64, 'Full name must be between 3 and 64 characters.')
    .matches(
      /^[a-zA-Z ]*$/,
      'Invalid characters found. Please try again by using characters from A to Z.'
    ),
  email: Yup.string().email('Invalid email'),
  agreeTermAndCondition: Yup.boolean().oneOf(
    [true],
    'Please tick the checkbox to continue'
  ),
});

interface Props {
  otpVerification: OtpVerification;
  phone: string;
}
const debouncedValidatePromoCode = debounce((data, cb) => {
  authService.validatePromoCode(data).then((val) => cb(null, val), cb);
}, 500);

export const useIntroduceYourselfForm = (props: Props) => {
  const onSubmit = useOnSubmit(props);
  const [firstSubmited, setFirstSubmited] = useState(false);

  const validate = ({ promoCode }: PromoCodeParam) => {
    setFirstSubmited(true);
    if (!promoCode) {
      debouncedValidatePromoCode.cancel();
      formik.setStatus({ isValidating: false });
      return Promise.resolve();
    }
    formik.setStatus({ isValidating: true });

    return new Promise((resolve, reject) => {
      debouncedValidatePromoCode(
        { ...props.otpVerification, promoCode },
        (error: ApiAxiosError | ValidationError) => {
          formik.setStatus({ isValidating: false });
          if (error) {
            reject(error);
          } else {
            resolve(undefined);
          }
        }
      );
    })
      .then(onlyValidationError(({ fields }) => fields))
      .catch(defaultOnError);
  };

  const [generalError, setGeneralError] = useState('');

  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      promoCode: '',
      emailSubscriptionStatus: false,
      agreeTermAndCondition: false,
      agreeTermAndConditionAt: new Date(),
      validationError: '',
    },
    validationSchema,
    validate,
    validateOnChange: firstSubmited,
    validateOnBlur: firstSubmited,
    onSubmit: (data) => {
      onSubmit(data).catch((error) => {
        setGeneralError(error.response?.data.message);
        defaultOnError(error);
      });
    },
  });

  return { ...formik, generalError };
};

function useOnSubmit({ otpVerification, phone }: Props) {
  const authModal = useVisibility(VisibilityName.AUTH);
  const qClient = useQueryClient();

  const onSubmit = async ({
    promoCode,
    ...data
  }: Omit<CreateAccountParams, 'phone'> & PromoCodeParam) => {
    await authService.createAccount({
      data: { ...data, phone },
      otpVerification,
    });
    await authService.setPromoCode(promoCode);

    setUserIsNew(qClient);
    authModal.close();

    await trackUserEvent(EVENT_TYPES.first_login);
  };
  return onSubmit;
}

// https://github.com/setel-engineering/api-accounts/blob/315b3e26e3efeb90c50782d156536d5264c94360/src/modules/accounts/accounts.enums.ts#L1
export enum AccountState {
  CreateAccount = 'createAccount',
  Registered = 'registered',
  NotRegistered = 'not-registered',
}

export type SendCreateSessionOtpParams = {
  phone: string;
  resend?: boolean;
  voice?: boolean;
};

export type VerifySessionOtpParams = {
  phone: string;
  otp: string;
};

export type OtpVerification = {
  accountState: AccountState;
  otpToken: string;
  phoneToken: string;
};

export type OtpTokens = Pick<OtpVerification, 'otpToken' | 'phoneToken'>;

export type CreateAccountParams = {
  email: string;
  phone: string;
  fullName: string;
  emailSubscriptionStatus: boolean;
  agreeTermAndConditionAt: Date;
  // TODO: adjust + attribution
};

export type LoginResponse = Token & {
  distinctId: string;
  expires_in: number;
};

export type CreateSessionByOtpParams = OtpTokens & {
  phone: string;
};

export type Token = {
  access_token: string;
  refresh_token: string;
};

export type PromoCodeParam = {
  promoCode: string;
};

export type ValidatePromoCodeParams = OtpTokens & PromoCodeParam;

export type SsoGetAuthTokenInput = {
  client_id: string; // find client secret that match clientId
  grant_type: SSOSessionGrantType;
  code?: string;
  refresh_token?: string;
  redirect_uri?: string;
};

export type SsoGetAuthTokenPayload = SsoGetAuthTokenInput & {
  client_secret: string;
};

export enum SSOSessionGrantType {
  AuthorizationCode = 'authorization_code',
  RefreshToken = 'refresh_token',
}

export type CreateAccountPinResponse = {
  id: string;
};

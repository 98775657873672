import cn from 'classnames';
import * as React from 'react';

type ImageRegularProps = {
  src?: string;
  className?: string;
  altTitle?: string;
  onLoadCall?: (ele: HTMLImageElement) => void;
};

export const ImageRegular: React.FC<
  React.PropsWithChildren<ImageRegularProps>
> = ({ src, className, altTitle, onLoadCall }) => {
  if (!src) return null;

  return (
    <img
      src={src}
      className={cn(className)}
      style={{
        backgroundClip: 'content-box',
        backgroundImage: `url(/assets/thumbnail_dealscard.svg)`,
      }}
      alt={altTitle}
      onLoad={(e) => {
        if (onLoadCall) onLoadCall(e.currentTarget);
        e.currentTarget.style.backgroundImage = 'none';
        e.currentTarget.style.backgroundColor = 'transparent';
      }}
    />
  );
};

import type { SetStateAction } from 'react';
import { createCtx } from '~/shared/createContext';
import { PlayerState } from './types';

export const [usePlayer, PlayerProvider] = createCtx<{
  state: PlayerState;
  player: YT.Player;
  fullScreen: boolean;
  setShowFullScreen: (v: SetStateAction<boolean>) => void;
  canNext?: boolean;
  canPrev?: boolean;
}>();

import { useState, useRef } from 'react';
import cn from 'classnames';
import { FormField } from '../Formik';
import { ProgressButton, PhoneInput, phoneValidation } from '~/components';
import {
  defaultOnError,
  onlyValidationError,
  ValidationError,
} from '../../domain/error';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { sendDownloadLink } from '../../domain/sms';
import { trackUserEvent } from '../../shared';
import { EVENT_TYPES } from '../../shared/analytics.events';
import { useTranslation } from 'react-i18next';

type DownloadViaSMSButtonProps = {
  label?: string;
  buttonClass?: string;
};

export const DownloadViaSMSButton = ({
  label,
  buttonClass,
}: DownloadViaSMSButtonProps) => {
  const { t } = useTranslation();
  const phoneInput = useRef<HTMLInputElement>();
  const handleValidationError = ({ fields }: ValidationError) => {
    formik.setErrors(fields);
  };

  const [completed, setCompleted] = useState(false);
  const formik = useFormik({
    initialValues: { phoneViaSMS: '' },
    validateOnBlur: false,
    validationSchema: Yup.object({
      phoneViaSMS: phoneValidation,
    }),
    onSubmit: (data) => {
      setCompleted(false);
      sendDownloadLink(data.phoneViaSMS)
        .then(() => {
          formik.setSubmitting(false);
          setCompleted(true);
          trackUserEvent(EVENT_TYPES.get_app, {
            user_phone: data.phoneViaSMS,
            action: 'submit',
          });
        })
        .catch(onlyValidationError(handleValidationError))
        .catch(defaultOnError);
    },
  });

  return (
    <div>
      <form
        className="flex flex-col items-start"
        onSubmit={(values) => {
          phoneInput.current!.blur();
          formik.handleSubmit(values);
        }}
      >
        <FormField
          ref={phoneInput}
          {...formik}
          placeholder="1234567890"
          name="phoneViaSMS"
          label={label}
          useSetValue
          component={PhoneInput}
          componentParams={{ inputClass: '!pl-[80px] rounded-lg' }}
        />
        <ProgressButton
          className={cn(buttonClass, 'flex-none')}
          isLoading={formik.isSubmitting}
          isSuccess={completed}
          afterSuccess={() => setCompleted(false)}
          disabled={!formik.isValid}
          type="submit"
        >
          {t('buttons.downloadViaSMS')}
        </ProgressButton>
      </form>
    </div>
  );
};

import classNames from 'classnames';

type MessageCountBadgeProps = { unreadCount: number };

export const MessageCountBadge = ({ unreadCount }: MessageCountBadgeProps) => (
  <>
    {!!unreadCount && (
      <span
        className={classNames(
          'inline-block bg-error text-white text-badge rounded-xl ml-2 text-center',
          'inbox-message-count-badge'
        )}
        children={unreadCount}
      />
    )}
  </>
);

import { ProgressButton, ProgressButtonProps } from './progress-button';
import { forwardRef } from 'react';
import cx from 'classnames';

export type DeleteButtonProps = ProgressButtonProps;

export const DeleteButton = forwardRef<HTMLButtonElement, DeleteButtonProps>(
  ({ className, variant = 'primary', ...props }, ref) => {
    return (
      <ProgressButton
        {...props}
        variant={variant}
        className={cx(
          !props.disabled &&
            {
              primary: [
                'border-error',
                'bg-error hover:bg-error-700 ',
                'active:ring-error-200',
                'focus-visible:ring-error-200',
                'outline-none',
              ],
              outline: [
                'text-error border-error',
                'hover:text-error hover:bg-error-100',
                'active:ring-error-200',
                'focus-visible:ring-error-200',
                'outline-none',
              ],
              error: [],
              ['error-outline']: [],
            }[variant],
          className
        )}
        ref={ref}
      />
    );
  }
);

import * as React from 'react';

export function ImageWithTitles({
  imageNode,
  title,
  subtitle,
}: {
  imageNode: React.ReactNode;
  title: string | React.ReactNode;
  subtitle: string | React.ReactNode;
}) {
  return (
    <div className="flex flex-col items-center my-6 space-y-2">
      {imageNode}
      <h4 className="text-xl text-gray-dark font-semibold text-center">
        {title}
      </h4>
      <span className="text-4 text-gray-light text-center leading-snug">
        {subtitle}
      </span>
    </div>
  );
}

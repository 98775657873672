import type appboy from '@braze/web-sdk';
import { getAppBoy, appBoyIsReady } from '~/shared/analytics';

export const subscribeInboxMessages = (): Promise<appboy.ContentCards> => {
  return new Promise((resolve) => {
    Promise.all([getAppBoy(), appBoyIsReady]).then(([appboy]) => {
      appboy.requestContentCardsRefresh();
      appboy.subscribeToContentCardsUpdates((response) => {
        appboy.logContentCardsDisplayed();
        return resolve(response);
      });
    });
  });
};

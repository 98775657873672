import { BareButton } from '@setel/web-ui';
import { ComponentType, SVGProps } from 'react';

type InfoActionCardProps = {
  title: string;
  subtitle?: string;
  actionText?: string;
  action?: () => void;
  icon?: ComponentType<React.PropsWithChildren<SVGProps<SVGSVGElement>>>;
};

export const InfoActionCard = ({
  title,
  subtitle,
  actionText,
  action,
  icon: RightDecoratorIcon,
}: InfoActionCardProps) => {
  return (
    <div className="flex flex-row rounded-lg shadow-info py-4 px-4 w-full">
      <div className="flex flex-col flex-grow mr-1">
        <span className="text-regular font-medium text-gray-dark">{title}</span>
        <span className="text-regular text-gray-medium">{subtitle}</span>
        <BareButton className="text-left mt-2" onClick={action}>
          {actionText}
        </BareButton>
      </div>
      {RightDecoratorIcon && (
        <RightDecoratorIcon className="flex justify-end flex-grow" />
      )}
    </div>
  );
};

import { Suspense, useState, useEffect } from 'react';
import { IconButton, MobileMenuProps, MobileMenu } from '~/components';
import { MenuIcon } from '@setel/web-ui';
import { Menu } from './Menu';
import { useRemixHasNavigated } from '~/shared/hooks';
import { ErrorBoundary } from '~/components/ErrorBoundary';

export const MenuNavigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const hasNavigated = useRemixHasNavigated();

  useEffect(() => {
    // close Menu after page navigation finished
    if (isOpen && hasNavigated) {
      setIsOpen(false);
    }
  }, [isOpen, hasNavigated]);

  return (
    <>
      <IconButton
        aria-label="Open Navigation Menu"
        className="block xl:hidden"
        children={<MenuIcon width={24} height={24} />}
        onClick={() => setIsOpen(true)}
      />

      {isOpen && (
        <MenuActionSheet isOpen={isOpen} onDismiss={() => setIsOpen(false)} />
      )}
    </>
  );
};

interface MenuPopupProps extends Omit<MobileMenuProps, 'onBack' | 'children'> {}

function MenuActionSheet(props: MenuPopupProps) {
  return (
    <ErrorBoundary fallback={<div />}>
      <Suspense fallback={null}>
        <MobileMenu
          {...props}
          onBack={props.onDismiss}
          overlayClassName="xl:hidden p-0 md:p-3"
        >
          <Menu />
        </MobileMenu>
      </Suspense>
    </ErrorBoundary>
  );
}

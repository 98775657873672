import { positionDefault } from '@setel/web-ui';
import { NavLinkProps, NavLink } from '~/components/nav-link';
import { Icon, Size } from '~/components';
import { MenuItemsListProps } from '~/components/MegaMenu/MegaMenu';
import { MegaMenuPopover } from '../MegaMenu/mega-menu-popover';

export interface TopNavLinkProps
  extends NavLinkProps,
    Partial<MenuItemsListProps> {
  popoverPosition?: typeof positionDefault;
}

const styles = {
  link: `inline-flex items-center text-gray-dark p-2 rounded focus:outline-none focus-visible:ring-brand focus-visible:ring-2`,
};

export const TopNavLink = ({
  children,
  items,
  popoverPosition,
  size,
  ...props
}: TopNavLinkProps) => {
  const hasItems = Boolean(items?.length);

  return hasItems ? (
    <>
      <MegaMenuPopover
        items={items}
        size={size}
        popoverPosition={popoverPosition}
        render={({ onClick, ref }) => (
          <button
            type="button"
            className={styles.link}
            aria-haspopup={true}
            ref={ref as any}
            onClick={onClick}
          >
            {children}
            <Icon
              name="arrow"
              aria-label="chevron down icon"
              className="inline-block ml-1"
              size={Size.SMALL}
            />
          </button>
        )}
      />
    </>
  ) : (
    <NavLink className={styles.link} exact {...props}>
      {children}
    </NavLink>
  );
};

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AccountState, OtpVerification } from '../../../domain/auth/auth.type';
import {
  verifySessionOtp,
  createSessionByOtp,
} from '../../../domain/auth/auth.service';
import {
  ValidationError,
  defaultOnError,
  getApiErrorMessage,
} from '../../../domain/error';
import { useVisibility } from '~/shared/hooks';
import { VisibilityName } from '~/store';
import { useVariables } from '~/domain/variable';

export const useVerifyOtpForm = ({
  goTo,
  goBack,
  phone,
}: {
  goTo: (otpVerification: OtpVerification) => void;
  goBack: (loginErrorMessage?: string) => void;
  phone: string;
}) => {
  const authModal = useVisibility(VisibilityName.AUTH);
  const { data: variables } = useVariables();
  const legacyDropInApi = !!variables?.web_prefer_legacy_dropin_api?.value;

  const formik = useFormik({
    initialValues: { otp: '' },
    validationSchema: Yup.object({
      pin: Yup.string().length(6, 'Verification code invalid or expired'),
    }),
    onSubmit: async ({ otp }) => {
      try {
        const { data } = await verifySessionOtp(
          { otp, phone },
          { legacyDropInApi }
        );
        if (data.accountState === AccountState.NotRegistered) {
          return goTo(data);
        }
        await createSessionByOtp({ ...data, phone }, { legacyDropInApi })
          .then(() => {
            authModal.close();
          })
          .catch((err) => {
            defaultOnError(err);
            goBack(getApiErrorMessage(err) ?? 'Something went wrong');
          });
      } catch (error) {
        if (error instanceof ValidationError) {
          formik.setErrors(error.fields);
        } else {
          formik.setFieldError('otp', getApiErrorMessage(error));
        }

        defaultOnError(error);
      }
    },
  });

  return formik;
};

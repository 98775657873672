import { ComponentProps, useEffect, useRef, useState } from 'react';
import { Popover } from '@setel/web-ui';
import { useOnInteractOutside } from './use-on-interact-outside';

export interface Props extends ComponentProps<(typeof Popover)['Content']> {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  renderTrigger: Required<
    ComponentProps<(typeof Popover)['Trigger']>
  >['render'];
  hoverToOpen?: boolean;
}

/**
 *  Popover that works when its trigger element is mounted inside a Shadow Root
 */
export const ShadowRootPopover = ({
  isOpen,
  onOpenChange,
  renderTrigger,
  hoverToOpen,
  ...contentProps
}: Props) => {
  const btnRef = useRef<HTMLElement | null>(null);
  const [contentRef, setContentRef] = useState<HTMLElement | null>(null);

  const captureHandlers = useOnInteractOutside({
    onInteractOutside: (ev) => {
      if (!isOpen) return;
      if (btnRef.current && ev.composedPath().includes(btnRef.current)) {
        return;
      }
      onOpenChange(false);
    },
  });

  useEffect(() => {
    if (!btnRef.current || !contentRef || !hoverToOpen) return;

    btnRef.current.addEventListener(
      'mouseenter',
      () => {
        setTimeout(() => {
          onOpenChange(true);
        }, 50); // delay 50ms to wait for other popover closed before opening new one
      },
      { once: true }
    );

    // track pointer's coordinate, if it moves out of the areas of popover's trigger and popover's content, close current popover
    const mouseMoveListener = (e: MouseEvent) => {
      if (!btnRef.current) return;

      const E: Point = { x: e.pageX, y: e.pageY - window.scrollY };

      // area 1
      const A: Point = {
        x: btnRef.current.getBoundingClientRect().x,
        y: btnRef.current.getBoundingClientRect().y,
      };
      const B: Point = {
        x: A.x + btnRef.current.getBoundingClientRect().width,
        y: A.y,
      };
      const C: Point = { x: B.x, y: contentRef.getBoundingClientRect().y };
      const D: Point = { x: A.x, y: C.y };

      // area 2
      const F: Point = {
        x: contentRef.getBoundingClientRect().x,
        y: contentRef.getBoundingClientRect().y,
      };
      const G: Point = {
        x: F.x + contentRef.getBoundingClientRect().width,
        y: F.y,
      };
      const H: Point = {
        x: G.x,
        y: F.y + contentRef.getBoundingClientRect().height,
      };
      const I: Point = { x: F.x, y: H.y };

      if (
        !(
          isPointInRectangle(A, B, C, D, E) || isPointInRectangle(F, G, H, I, E)
        )
      ) {
        onOpenChange(false);
      }
    };

    if (!isOpen) return;

    document.addEventListener('mousemove', mouseMoveListener);
    return () => {
      document.removeEventListener('mousemove', mouseMoveListener);
    };
  }, [btnRef, contentRef, onOpenChange, isOpen, hoverToOpen]);

  return (
    <Popover isOpen={isOpen}>
      <Popover.Trigger
        render={({ ref, children }) => {
          return renderTrigger({
            children,
            onClick: () => {
              onOpenChange(!isOpen);
            },
            ref: (node) => {
              typeof ref === 'function' && ref(node);
              btnRef.current = node;
            },
          });
        }}
      />

      <Popover.Content
        {...captureHandlers}
        {...contentProps}
        ref={(node) => {
          setContentRef(node);
        }}
      />
    </Popover>
  );
};

type Point = { x: number; y: number };

// check if point E is in rectangle ABCD
function isPointInRectangle(A: Point, B: Point, C: Point, D: Point, E: Point) {
  let AEB = triangleArea(A, E, B);
  let BEC = triangleArea(B, E, C);
  let CED = triangleArea(C, E, D);
  let DEA = triangleArea(D, E, A);
  let ABCD = rectangleArea(A, B, D);
  if (ABCD === AEB + BEC + CED + DEA) {
    return true;
  }
  return false;
}
// calculate area of a triangle
function triangleArea(A: Point, B: Point, C: Point): number {
  return Math.abs(
    (A.x * (B.y - C.y) + B.x * (C.y - A.y) + C.x * (A.y - B.y)) / 2
  );
}
// calculate distance between 2 points
function distanceOf2Points(A: Point, B: Point) {
  return Math.sqrt(Math.pow(A.x - B.x, 2) + Math.pow(A.y - B.y, 2));
}
// calculate area of a rectangle
function rectangleArea(A: Point, B: Point, D: Point) {
  // ABCD
  const AB = distanceOf2Points(A, B);
  const AD = distanceOf2Points(A, D);
  return AB * AD;
}

import { FC, ReactNode } from 'react';
import cn from 'classnames';
import { BgColor } from '~/components/theme';

export type InfoStatusProps = {
  variant?: BgColor;
  children?: ReactNode;
  className?: string;
};

export const InfoStatus: FC<React.PropsWithChildren<InfoStatusProps>> = ({
  children,
  className,
  variant,
}) => {
  return (
    <div className={cn(className)}>
      <div
        className={cn(
          'rounded text-white text-xs uppercase inline-block w-auto px-2 font-medium',
          variant || 'bg-brand'
        )}
      >
        {children}
      </div>
    </div>
  );
};

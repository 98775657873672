import { Trans } from 'react-i18next';
import { DownloadViaSMSButton } from './DownloadViaSMSButton';

export const DownloadViaSMS = () => {
  return (
    <div className="flex gap-4 flex-wrap lg:flex-nowrap place-items-stretch">
      <div className="w-full lg:flex-auto">
        <h2 className="font-medium mb-2">
          <Trans i18nKey="downloadViaSMS.title">
            Get the Setel app for a seamless refuel & retail on-the-go
            experience!
          </Trans>
        </h2>
        <p className="font-light text-gray-500 text-sm">
          <Trans i18nKey="downloadViaSMS.content">
            Your privacy matters. Learn more about how we collect data to
            personalise your experience in our{' '}
            <a
              className="text-blue-500"
              href={'https://www.setel.com/privacy/'}
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy policy
            </a>
            .
          </Trans>
        </p>
      </div>
      <div className="flex-none w-full lg:w-7/12 xl:w-1/2">
        <DownloadViaSMSButton />
      </div>
    </div>
  );
};

import { apiClient } from '~/shared/axios';
import type { Attribute, AttributesEntity } from './attributes.types';

export const getAttributes = <T extends string>() =>
  apiClient
    .get<AttributesEntity<T>>(`/attributes/entities`)
    .then((resp) => resp.data);

export const updateAttribute = <T extends string>({
  key,
  value,
}: Attribute<T>) =>
  apiClient.post(`/attributes/entities`, {
    attributes: { [key]: value },
    actionType: 'set',
  });

import { IconButton as WebUiIconButton } from '@setel/web-ui';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import classNames from 'classnames';

export const IconButton = forwardRef<
  HTMLButtonElement,
  ComponentPropsWithoutRef<'button'>
>(({ className, ...props }, ref) => (
  <WebUiIconButton
    className={classNames('w-6 h-6 text-gray-dark', className)}
    {...props}
    ref={ref}
  />
));

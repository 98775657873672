import { DropdownItem } from '@setel/web-ui';
import { ReactNode } from 'react';
import classNames from 'classnames';

type IconMenuItemProps = {
  children: ReactNode;
  onSelect: () => void;
  className?: string;
};

export const IconMenuItem = ({
  children,
  onSelect,
  className,
}: IconMenuItemProps) => (
  <DropdownItem
    className={classNames('flex gap-2', 'inbox-icon-menu-item', className)}
    onClick={(e) => e.stopPropagation()}
    {...{ onSelect, children }}
  />
);

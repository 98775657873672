import { MiniButton } from '@setel/web-ui';
import { useTranslation } from 'react-i18next';
import { useVisibility } from '~/shared/hooks';
import { VisibilityName } from '~/store/visibility';

export const LoginButton = () => {
  let { t } = useTranslation();
  const modal = useVisibility(VisibilityName.AUTH);
  return (
    <>
      <MiniButton
        onClick={modal.open}
        variant="outline"
        className="sm:text-sm sm:h-10 sm:px-6"
      >
        {t('navbar.login')}
      </MiniButton>
    </>
  );
};

import { ChangeEvent, forwardRef } from 'react';
import * as Yup from 'yup';
import { Input, InputProps } from '../Input';
import { MalaysiaFlagPrefix } from './MalaysiaFlagPrefix';

const PHONE_CODE = '60';
const MIN_LENGTH = 11;
const MAX_LENGTH = 12;

export type PhoneInputProps = Omit<
  InputProps,
  'before' | 'after' | 'onChange'
> & {
  bypassValidity?: boolean;
  onChange?(phone: string): void;
};

export const PhoneInput = forwardRef<any, PhoneInputProps>(
  (
    { onChange: rawOnChange, value: rawValue, bypassValidity, ...props },
    ref
  ) => {
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (!rawOnChange) return;
      if (!bypassValidity && !e.target.validity.valid) return;

      rawOnChange(`${PHONE_CODE}${e.target.value}`);
    };

    const value = rawValue
      ?.toString()
      .replace(new RegExp(`^${PHONE_CODE}`), '');

    return (
      <Input
        ref={ref}
        type="tel"
        inputClass="!pl-[80px] border-grey-700"
        pattern={`[1-9]{1}[0-9]{0,${MAX_LENGTH - PHONE_CODE.length - 1}}`}
        maxLength={MAX_LENGTH - PHONE_CODE.length}
        before={<MalaysiaFlagPrefix phoneCode={PHONE_CODE} />}
        onChange={onChange}
        value={value}
        {...props}
      />
    );
  }
);
const LENGTH_ERROR = `Invalid Number`;
export const phoneValidation = Yup.string()
  .min(MIN_LENGTH, LENGTH_ERROR)
  .max(MAX_LENGTH, LENGTH_ERROR)
  .required('Required');

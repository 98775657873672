import cn from 'classnames';
import {
  NavLink as RemixNavLink,
  NavLinkProps as RemixNavLinkProps,
  useLocation,
} from '@remix-run/react';
import * as React from 'react';

export interface NavLinkProps
  extends React.PropsWithChildren<
    Omit<RemixNavLinkProps, 'to' | 'end' | 'className' | 'style' | 'children'>
  > {
  href: string;
  activeClassName?: string;
  exact?: boolean;
  isActive?: (path: string) => boolean;
  className?: string;
}

export const NavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>(
  function NavLink(
    { href, activeClassName, className, exact, isActive, ...props },
    forwardefRef
  ) {
    const isAbsLink = href.match(/^https?\:\/\//);

    const { pathname } = useLocation();
    const shouldBeActive = typeof isActive === 'function' && isActive(pathname);

    return isAbsLink ? (
      <a {...props} href={href} className={className} ref={forwardefRef} />
    ) : (
      <RemixNavLink
        {...props}
        to={href}
        end={exact}
        className={({ isActive: linkIsActive }) =>
          cn(className, (linkIsActive || shouldBeActive) && activeClassName)
        }
        ref={forwardefRef}
      />
    );
  }
);

import { CSSTransitionProps } from './Transition';

export enum TransitionType {
  FADE_IN_DOWN = 'FadeInDown',
}

export type AnimationProps = Pick<
  CSSTransitionProps,
  'show' | 'isChild' | 'enterDuration' | 'leaveDuration'
>;

export enum Duration {
  S75 = 75,
  S100 = 100,
  S150 = 150,
  S200 = 200,
  S300 = 300,
  S500 = 500,
  S700 = 700,
  S1000 = 1000,
}

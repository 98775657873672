import { useRef } from 'react';
import type { Slider } from '~/components/Slick';
import { GalleryPagination } from './GalleryPagination';
import { Main, MainProps } from './Main';

export type GalleryProps = Omit<MainProps, 'pagination'>;

export const Gallery = ({ images, extra, imageComponent }: GalleryProps) => {
  const main = useRef<Slider>(null);
  const pagination = useRef<Slider>(null);

  return (
    <>
      <Main
        extra={extra}
        images={images}
        pagination={pagination}
        ref={main}
        imageComponent={imageComponent}
      />
      <GalleryPagination
        ref={pagination}
        images={images}
        main={main}
        imageComponent={imageComponent}
      />
    </>
  );
};

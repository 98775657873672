import type appboy from '@braze/web-sdk';
import { subscribeInboxMessages } from '~/domain/inbox';
import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { extractMessageId } from './extractMessageId';
import partition from 'lodash-es/partition';
import { useAppboy } from '~/shared/analytics';
import { useParams } from '@remix-run/react';

const QUERY_KEY = 'list-inbox';
export const useInboxMessages = () => {
  const queryCache = useQueryClient();
  const appboyInstance = useAppboy();

  const { data: response, isLoading } = useQuery(
    [QUERY_KEY],
    subscribeInboxMessages
  );

  const isControlCard = useCallback(
    (card: appboy.ClassicCard) =>
      !!appboyInstance && card instanceof appboyInstance.ControlCard,
    [appboyInstance]
  );

  const [controlCards, messages] = partition(
    response?.cards as appboy.ClassicCard[],
    isControlCard
  );

  const invalidateQueries = useCallback(
    () => queryCache.invalidateQueries(QUERY_KEY),
    [queryCache]
  );

  const markAllAsRead = () => {
    if (appboyInstance) {
      appboyInstance.logCardImpressions(messages, true);
    }
    invalidateQueries();
  };

  const deleteAllMessages = () => {
    messages?.forEach(
      (message) => appboyInstance && appboyInstance.logCardDismissal(message)
    );
    invalidateQueries();
  };

  const markAsRead = useCallback(
    (message: appboy.ClassicCard) => {
      if (appboyInstance) {
        appboyInstance.logCardImpressions([message], true);
      }
      if (!isControlCard(message)) invalidateQueries();
    },
    [isControlCard, invalidateQueries, appboyInstance]
  );

  const deleteMessage = (message: appboy.ClassicCard) => {
    if (appboyInstance) {
      appboyInstance.logCardDismissal(message);
    }
    invalidateQueries();
  };

  return {
    isLoading,
    markAsRead,
    deleteMessage,
    markAllAsRead,
    deleteAllMessages,
    messages,
    controlCards,
    unreadCount: response?.getUnviewedCardCount() || 0,
  };
};

export const useGetMessage = () => {
  const { messages, isLoading, deleteMessage } = useInboxMessages();
  const { messageId } = useParams();

  return {
    isLoading,
    deleteMessage,
    data: messages.find(
      (message) => extractMessageId(message.id) === messageId
    ),
  };
};

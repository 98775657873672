import { FC } from 'react';
import cn from 'classnames';

export type CardProps = {
  className?: string;
  hideBorderOnMobile?: boolean;
  overwriteWidth?: boolean;
};

const classes = [
  'rounded-lg',
  'flex',
  'flex-col',
  'border-gray-light',
  'border-opacity-10',
  'bg-white',
  'overflow-hidden',
];

export const Card: FC<React.PropsWithChildren<CardProps>> = ({
  children,
  className,
  hideBorderOnMobile,
  overwriteWidth,
}) => {
  return (
    <div
      className={cn(classes, className, {
        'border-l border-t shadow-card': !hideBorderOnMobile,
        'border-0 md:border-l md:border-t md:shadow-card': hideBorderOnMobile,
        'w-full': !overwriteWidth,
      })}
    >
      {children}
    </div>
  );
};

import { FC } from 'react';
import * as React from 'react';
import cn from 'classnames';

export type GalleryCounterProps = {
  current: number;
  total: number;
};

const CLASSES = [
  'md:hidden',
  'rounded-full',
  'absolute bottom-0 right-0',
  'text-white tracking-wide font-medium text-sm',
  'bg-black bg-opacity-50',
  'm-4 px-2 py-1',
];

export const GalleryCounter: FC<
  React.PropsWithChildren<GalleryCounterProps>
> = ({ current, total }) => {
  if (total === 1) return null;
  return (
    <div className={cn(CLASSES)}>
      {current + 1}/{total}
    </div>
  );
};

import { NavigationHeader } from '~/components';
import * as React from 'react';
import {
  MailIcon,
  TrashIcon,
  ActionSheet,
  BellIcon,
  DotVerticalIcon,
  IconButton,
} from '@setel/web-ui';
import { ConfirmDeleteModal } from './ConfirmDeleteModal';
import { IconMenu } from './IconMenu';
import { IconMenuItem } from './IconMenuItem';
import { IconMenuItems } from './IconMenuItems';
import { MessageCountBadge } from './MessageCountBadge';
import { ProfileRoute } from '~/features/Profile';
import { useInboxMessages } from './useInboxMessages';
import { useNavigate } from '@remix-run/react';

type InboxHeaderProps = {
  unreadCount: number;
  messageCount: number;
  onClose: () => void;
};

export const InboxHeader = ({
  unreadCount,
  messageCount,
  onClose,
}: InboxHeaderProps) => (
  <NavigationHeader
    onBack={onClose}
    rightIcon={
      messageCount > 0 && (
        <>
          <div className="md:hidden">
            <MobileContextMenu />
          </div>
          <div className="hidden md:block">
            <DesktopContextMenu />
          </div>
        </>
      )
    }
  >
    <div>
      <span className="text-base md:text-xl text-gray-dark font-medium">
        Inbox
      </span>
      <span className="md:hidden">
        <MessageCountBadge unreadCount={unreadCount} />
      </span>
    </div>
  </NavigationHeader>
);

const MobileContextMenu = () => {
  const navigate = useNavigate();
  const [isMenuVisible, setIsMenuVisible] = React.useState(false);
  const [isConfirmVisible, setIsConfirmVisible] = React.useState(false);
  const { markAllAsRead, deleteAllMessages } = useInboxMessages();
  return (
    <>
      <IconButton className="w-6 h-6" onClick={() => setIsMenuVisible(true)}>
        <DotVerticalIcon />
      </IconButton>
      <ActionSheet
        isOpen={isMenuVisible}
        onDismiss={() => setIsMenuVisible(false)}
        cancelButtonText="cancel"
      >
        <ActionSheet.Content>
          <ActionSheet.Item
            destructiveAction
            leftIcon={<TrashIcon />}
            onClick={() => {
              setIsMenuVisible(false);
              setIsConfirmVisible(true);
            }}
          >
            Delete all messages
          </ActionSheet.Item>
          <ActionSheet.Item
            leftIcon={<MailIcon />}
            onClick={() => {
              markAllAsRead();
              setIsMenuVisible(false);
            }}
          >
            Mark all as read
          </ActionSheet.Item>
          <ActionSheet.Item
            leftIcon={<BellIcon />}
            onClick={() => {
              setIsMenuVisible(false);
              navigate(ProfileRoute.NOTIFICATIONS);
            }}
          >
            Notification settings
          </ActionSheet.Item>
        </ActionSheet.Content>
      </ActionSheet>
      <ConfirmDeleteModal
        header="Delete all messages?"
        body="Once you delete all messages in your inbox, you won’t be
        able to undo it."
        isOpen={isConfirmVisible}
        onClose={() => setIsConfirmVisible(false)}
        onConfirm={() => {
          deleteAllMessages();
          setIsConfirmVisible(false);
        }}
      />
    </>
  );
};

const DesktopContextMenu = () => {
  const { markAllAsRead, deleteAllMessages } = useInboxMessages();
  const [isConfirmVisible, setIsConfirmVisible] = React.useState(false);
  return (
    <>
      <IconMenu aria-label="Inbox Actions">
        <IconMenuItems popoverClass="z-100">
          <IconMenuItem
            onSelect={() => setIsConfirmVisible(true)}
            className="text-error"
          >
            <TrashIcon /> Delete all messages
          </IconMenuItem>
          <IconMenuItem onSelect={markAllAsRead}>
            <MailIcon className="text-gray-medium" /> Mark all as read
          </IconMenuItem>
        </IconMenuItems>
      </IconMenu>
      <ConfirmDeleteModal
        header="Delete all messages?"
        body="Once you delete all messages in your inbox, you won’t be
        able to undo it."
        isOpen={isConfirmVisible}
        onClose={() => setIsConfirmVisible(false)}
        onConfirm={() => {
          deleteAllMessages();
          setIsConfirmVisible(false);
        }}
      />
    </>
  );
};

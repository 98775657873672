import {
  useState,
  createContext,
  useContext,
  startTransition,
  PropsWithChildren,
  Dispatch,
  SetStateAction,
} from 'react';
import type { Config } from '~/shared';
import { Script } from '~/components/Script';
import { noop } from '@setel/web-ui';

// use Context to pass Zendesk isReady state down the tree
const IsZendeskReadyCtx = createContext<
  [boolean, Dispatch<SetStateAction<boolean>>]
>([false, noop]);

export const useZendeskWidgetCtx = () => {
  const [isZeReady] = useContext(IsZendeskReadyCtx);
  return { isZeReady };
};

export function ZendeskWidgetProvider({ children }: PropsWithChildren<{}>) {
  const isReady = useState(false);
  return (
    <IsZendeskReadyCtx.Provider value={isReady}>
      {children}
    </IsZendeskReadyCtx.Provider>
  );
}

export function ZendeskWidgetScript({
  KEY,
  ENABLED,
}: Config['ZENDESK_WIDGET_CONFIG']) {
  const [, setIsZeReady] = useContext(IsZendeskReadyCtx);
  return (
    <>
      {ENABLED && Boolean(KEY) && (
        <Script
          id="ze-snippet"
          src={`https://static.zdassets.com/ekr/snippet.js?key=${KEY}`}
          async
          onLoad={() => {
            startTransition(() => {
              setIsZeReady(true);
            });
          }}
        />
      )}
    </>
  );
}

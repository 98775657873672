import { BareButton, HelpIcon, IconButton } from '@setel/web-ui';
import { type ReactNode, useEffect } from 'react';
import { useNavigate, useSearchParams } from '@remix-run/react';
import { UserNavigation } from '~/features/Navigation/UserNavigation';
import { useUserProfile } from '~/features/Auth/hooks';
import { useAccountAttributes } from '~/features/Attributes';
import { GetAppButton } from '~/components';
import { LoginButton } from './LoginButton';
import { useTranslation } from 'react-i18next';

const LinkToHelp = (props: any) => (
  <a
    href="https://help.setel.com"
    target="_blank"
    rel="noopener noreferrer"
    aria-label="Help"
    {...props}
  />
);

const AuthShell = ({ children }: { children?: ReactNode }) => {
  let { t } = useTranslation();
  return (
    <div className="flex items-center gap-2 md:gap-4">
      <IconButton className="text-gray-medium md:hidden" render={LinkToHelp}>
        <HelpIcon className="w-6 h-6" />
      </IconButton>

      <BareButton
        className="hidden md:inline-flex text-gray-medium font-normal"
        underlineOnInteract={false}
        render={LinkToHelp}
        leftIcon={<HelpIcon className="w-6 h-6 mr-2" />}
      >
        {t('navbar.help')}
      </BareButton>

      {children}
    </div>
  );
};

export function AuthControls() {
  const { data: profile, isLoading: isProfileLoading } = useUserProfile();
  const { accountAppInstall: appInstalled, isLoading: isAttrLoading } =
    useAccountAttributes();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectAfterLogin = searchParams.get('redirectAfterLogin');

  useEffect(() => {
    if (profile && redirectAfterLogin) {
      navigate(redirectAfterLogin, { replace: true });
    }
  }, [Boolean(profile), redirectAfterLogin]);

  if ((isProfileLoading && !profile) || isAttrLoading) return null;

  return (
    <AuthShell>
      {!appInstalled && (
        <GetAppButton className="hidden xl:inline-flex" openMode="dropdown" />
      )}
      {Boolean(profile) ? <UserNavigation /> : <LoginButton />}
    </AuthShell>
  );
}

import { UseQueryOptions, useQuery } from 'react-query';
import {
  getAttributes,
  AttributesEntity,
  AcccountAttributeName,
} from '~/domain/attributes';
import { defaultOnError } from '~/domain/error';
import { useAuthToken } from '~/features/Auth/hooks';
import type { CamelizeString } from '~/shared/types';

export function useAttributes<AttributeNameT extends string>({
  suspense,
}: Pick<UseQueryOptions, 'suspense'> = {}) {
  const token = useAuthToken();
  return useQuery<AttributesEntity<AttributeNameT> | null>(
    ['user/attributes-entities', token],
    token ? getAttributes : () => null,
    { onError: defaultOnError, notifyOnChangeProps: 'tracked', suspense }
  );
}

export function useAccountAttributes(
  options?: Pick<UseQueryOptions, 'suspense'>
): Record<CamelizeString<AcccountAttributeName>, boolean> & {
  isLoading: boolean;
} {
  const { data, isLoading } = useAttributes<AcccountAttributeName>(options);
  return {
    isLoading,
    accountAppInstall: data?.attributes?.account_app_install?.value ?? false,
  };
}

/*
Based on official NextJS example: https://github.com/vercel/next.js/tree/canary/examples/with-redux
*/

import {
  Action,
  configureStore,
  type ConfigureStoreOptions,
  ThunkAction,
} from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { breadcrumbSlice } from '../store/breadcrumb';
import { visibilitySlice } from './visibility';
import { zendeskTagSlice } from './zendeskTags';

export type Store = ReturnType<typeof initStore>;

export let store: Store | undefined;

export type RootState = ReturnType<NonNullable<typeof store>['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

type PreloadedState = ConfigureStoreOptions<RootState>['preloadedState'];

export function initStore(preloadedState?: any) {
  return configureStore({
    reducer: {
      [breadcrumbSlice.name]: breadcrumbSlice.reducer,
      visibility: visibilitySlice.reducer,
      zendeskTag: zendeskTagSlice.reducer,
    },
    preloadedState,
  });
}

const initializeStore = (preloadedState?: PreloadedState) => {
  let _store = store ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export const useStore = (preloadedState?: PreloadedState) => {
  const store = useMemo(
    () => initializeStore(preloadedState),
    [preloadedState]
  );
  return store;
};

import { DropdownMenuItems, positionRightCenter } from '@setel/web-ui';
import { ReactNode } from 'react';

type IconMenuItemsProps = {
  children: ReactNode;
  popoverClass?: string;
};

export const IconMenuItems = ({
  children,
  popoverClass,
}: IconMenuItemsProps) => (
  <DropdownMenuItems
    className="min-w-44"
    getPosition={positionRightCenter}
    {...{ popoverClass, children }}
  />
);

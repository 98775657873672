import { FC } from 'react';
import * as React from 'react';
import cn from 'classnames';

export enum ExtraPosition {
  RIGHT = 'right',
  LEFT = 'left',
}

export const Extra: FC<
  React.PropsWithChildren<{ position: ExtraPosition }>
> = ({ position, children }) => {
  return (
    <div
      className={cn('absolute flex border border-transparent h-full mx-3', {
        'right-0': position === ExtraPosition.RIGHT,
      })}
    >
      <div className="text-gray flex items-center justify-center h-full w-full">
        {children}
      </div>
    </div>
  );
};

export const Before: FC<React.PropsWithChildren<unknown>> = (props) => (
  <Extra {...props} position={ExtraPosition.LEFT} />
);
export const After: FC<React.PropsWithChildren<unknown>> = (props) => (
  <Extra {...props} position={ExtraPosition.RIGHT} />
);

import * as React from 'react';
import { PhoneInput } from '~/components';
import { Alert, Button } from '@setel/web-ui';
import { ImageWithTitles } from '~/components/image-with-titles';
import { FormField } from '../Formik';
import { useSendOtpForm } from './hooks/useSendOtpForm';
import { trackUserEvent } from '~/shared';
import { EVENT_CHANNELS, EVENT_TYPES } from '~/shared/analytics.events';
import { RootNavigationRoute } from '../Navigation';
import { useReCAPTCHA } from '~/components/ReCAPTCHA';

type SendOtpProps = {
  formik: ReturnType<typeof useSendOtpForm>;
  title?: string;
  subtitle?: string;
  loginErrorMessage?: string;
};

const inputName = 'phone';

export const SendOtp = ({
  formik,
  title,
  subtitle,
  loginErrorMessage,
}: SendOtpProps) => {
  const phoneInput = React.useRef<HTMLInputElement>();

  React.useEffect(() => {
    location.pathname ===
      RootNavigationRoute.MESRA_CARD_REGISTER_LANDING_PAGE &&
      trackUserEvent(
        EVENT_TYPES.cardless_mesra_pageview_enter_mobile_number,
        {},
        [EVENT_CHANNELS.MIXPANEL]
      );
    let interval = setTimeout(() => phoneInput.current?.focus(), 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const recaptcha = useReCAPTCHA();
  const errorMsg = recaptcha.error?.message ?? loginErrorMessage;

  React.useEffect(() => {
    if (recaptcha.isSuccess) {
      formik.submitForm();
    }
  }, [recaptcha.isSuccess, formik.submitForm]);

  return (
    <div className="flex flex-col items-center">
      <ImageWithTitles
        imageNode={
          <img
            src="/assets/spot-phone.svg"
            className="inline-block h-20 w-20"
          />
        }
        title={title ?? 'Enter your mobile number'}
        subtitle={
          subtitle ?? 'A verification code will be sent to this mobile number'
        }
      />

      {errorMsg && (
        <Alert
          variant="error"
          className="mb-1 md:mb-2 mt-1 md:mt-4"
          description={errorMsg}
        />
      )}

      <form
        className="w-full mt-4 px-1"
        onSubmit={(e) => {
          if (recaptcha.isIdle) {
            e.preventDefault();
            recaptcha.execute();
            return;
          }

          phoneInput.current!.blur();
          formik.handleSubmit(e);
          window.location.pathname ===
            RootNavigationRoute.MESRA_CARD_REGISTER_LANDING_PAGE &&
            trackUserEvent(
              EVENT_TYPES.cardless_mesra_continue_enter_mobile_number,
              {},
              [EVENT_CHANNELS.MIXPANEL]
            );
        }}
      >
        <FormField
          ref={phoneInput}
          {...formik}
          placeholder="Enter mobile number"
          name={inputName}
          useSetValue
          component={PhoneInput}
        />
        <Button
          type="submit"
          isLoading={recaptcha.isSubmitting || formik.isSubmitting}
          disabled={!formik.isValid}
          className="w-full"
        >
          Continue
        </Button>
      </form>
    </div>
  );
};

import { DotVerticalIcon, DropdownMenu } from '@setel/web-ui';
import { ReactNode } from 'react';

type IconMenuProps = {
  children: ReactNode;
  ariaLabel?: string;
  labelIcon?: ReactNode;
  className?: string;
};

export const IconMenu = ({
  children,
  ariaLabel,
  labelIcon = <DotVerticalIcon className="w-6 h-6" />,
  className = 'w-full h-full',
}: IconMenuProps) => (
  <DropdownMenu
    data-icon-menu
    variant="icon"
    aria-label={ariaLabel}
    className={className}
    label={labelIcon}
    children={children}
    onClick={(e) => e.stopPropagation()}
  />
);

import * as React from 'react';
import cn from 'classnames';
import { positionDefault } from '@setel/web-ui';
import { NestedNavMenu, MenuItemsListProps } from './MegaMenu';
import { ShadowRootPopover, Props as PopoverProps } from './shadowroot-popover';
import { createPortal } from 'react-dom';
import { ClientOnly } from 'remix-utils/client-only';

export interface TopNavLinkProps
  extends Partial<MenuItemsListProps>,
    Pick<PopoverProps, 'className'> {
  render: PopoverProps['renderTrigger'];
  popoverPosition?: typeof positionDefault;
  onOpenChange?: (val: boolean) => void;
}

export const MegaMenuPopover = ({
  items,
  popoverPosition,
  render,
  size,
  className,
  onOpenChange,
}: TopNavLinkProps) => {
  const hasItems = Boolean(items?.length);
  const [isOpen, setIsOpen] = React.useState(false);

  const setOpen = (val: boolean) => {
    onOpenChange?.(val);
    setIsOpen(val);
  };

  if (!hasItems) return null;

  return (
    <>
      <ShadowRootPopover
        className={className}
        isOpen={isOpen}
        onOpenChange={setOpen}
        position={popoverPosition}
        popoverOffsetX={114}
        arrowOffsetX={140}
        wrapperClass={cn('hidden xl:block', isOpen && 'z-50')}
        renderTrigger={render}
        hoverToOpen
      >
        <div
          className={cn({
            // prevent flash of content content (visible for a short period)
            // before reach-portal's styles got injected
            hidden: !isOpen,
          })}
        >
          <NestedNavMenu
            items={items!}
            size={size}
            className={size === 'sm' ? 'p-5' : ''}
          />
        </div>
      </ShadowRootPopover>

      {/* show dark background under opened popover */}
      <ClientOnly>
        {() =>
          createPortal(
            <div
              aria-hidden
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                background: 'black',
                opacity: isOpen ? 0.3 : 0,
                visibility: isOpen ? 'visible' : 'hidden',
                width: '100%',
                height: '100vh',
                transition: 'visibility 0s, opacity 0.1s linear',
              }}
              data-testid="popover-dark-background"
            />,
            document.body
          )
        }
      </ClientOnly>
    </>
  );
};

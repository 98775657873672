// https://gitlab.com/setel/packages/web/-/blob/c0cc171/packages/web-utils/src/hooks/use-on-interact-outside.ts
// cloned to workaround issue with ShadowDOM's event target
// by using `event.composedPath()` instead of `event.target`.

import { useCallbackRef } from '@setel/web-utils';
import * as React from 'react';

/**
 * Listen for pointer down and focus outside an element.
 *
 * This customer hook returns props to be spreaded to element.
 */
export const useOnInteractOutside = (options: {
  onInteractOutside?: (ev: PointerDownOutsideEvent | FocusOutsideEvent) => void;
}) => {
  const pointerDownProps = usePointerDownOutside(options.onInteractOutside);
  const focusOutsideProps = useFocusOutside(options.onInteractOutside);

  return {
    ...pointerDownProps,
    ...focusOutsideProps,
  };
};

/* -------------------------------------------------------------------------------------------------
 * Utility hooks
 * -----------------------------------------------------------------------------------------------*/

const POINTER_DOWN_OUTSIDE = 'dismissableLayer.pointerDownOutside';
const FOCUS_OUTSIDE = 'dismissableLayer.focusOutside';
type PointerDownOutsideEvent = CustomEvent<{ originalEvent: PointerEvent }>;
type FocusOutsideEvent = CustomEvent<{ originalEvent: FocusEvent }>;

/**
 * Sets up `pointerdown` listener which listens for events outside a react subtree.
 *
 * We use `pointerdown` rather than `pointerup` to mimic layer dismissing behaviour
 * present in OS which usually happens on `pointerdown`.
 *
 * Returns props to pass to the node we want to check for outside events.
 */

function usePointerDownOutside(
  onPointerDownOutside?: (event: PointerDownOutsideEvent) => void
) {
  const handlePointerDownOutside = useCallbackRef(
    onPointerDownOutside
  ) as EventListener;
  const isPointerInsideReactTreeRef = React.useRef(false);

  React.useEffect(() => {
    const handlePointerDown = (event: PointerEvent) => {
      const [target] = event.composedPath(); // NOTE: to get target from inside ShadowDOM
      if (target && !isPointerInsideReactTreeRef.current) {
        const pointerDownOutsideEvent: PointerDownOutsideEvent =
          new CustomEvent(POINTER_DOWN_OUTSIDE, {
            bubbles: false,
            cancelable: true,
            detail: { originalEvent: event },
          });
        target.addEventListener(
          POINTER_DOWN_OUTSIDE,
          handlePointerDownOutside,
          { once: true }
        );
        target.dispatchEvent(pointerDownOutsideEvent);
      }
      isPointerInsideReactTreeRef.current = false;
    };
    /**
     * if this hook executes in a component that mounts via a `pointerdown` event, the event
     * would bubble up to the document and trigger a `pointerDownOutside` event. We avoid
     * this by delaying the event listener registration on the document.
     * This is not React specific, but rather how the DOM works, ie:
     * ```
     * button.addEventListener('pointerdown', () => {
     *   console.log('I will log');
     *   document.addEventListener('pointerdown', () => {
     *     console.log('I will also log');
     *   })
     * });
     */
    const timerId = window.setTimeout(() => {
      document.addEventListener('pointerdown', handlePointerDown);
    }, 0);
    return () => {
      window.clearTimeout(timerId);
      document.removeEventListener('pointerdown', handlePointerDown);
    };
  }, [handlePointerDownOutside]);

  return {
    // ensures we check React component tree (not just DOM tree)
    onPointerDownCapture: () => (isPointerInsideReactTreeRef.current = true),
  };
}

/**
 * Listens for when focus happens outside a react subtree.
 * Returns props to pass to the root (node) of the subtree we want to check.
 */

function useFocusOutside(onFocusOutside?: (event: FocusOutsideEvent) => void) {
  const handleFocusOutside = useCallbackRef(onFocusOutside) as EventListener;
  const isFocusInsideReactTreeRef = React.useRef(false);

  React.useEffect(() => {
    const handleFocus = (event: FocusEvent) => {
      const [target] = event.composedPath(); // NOTE: to get target from inside ShadowDOM
      if (target && !isFocusInsideReactTreeRef.current) {
        const focusOutsideEvent: FocusOutsideEvent = new CustomEvent(
          FOCUS_OUTSIDE,
          {
            bubbles: false,
            cancelable: true,
            detail: { originalEvent: event },
          }
        );
        target.addEventListener(FOCUS_OUTSIDE, handleFocusOutside, {
          once: true,
        });
        target.dispatchEvent(focusOutsideEvent);
      }
    };
    document.addEventListener('focusin', handleFocus);
    return () => document.removeEventListener('focusin', handleFocus);
  }, [handleFocusOutside]);

  return {
    onFocusCapture: () => (isFocusInsideReactTreeRef.current = true),
    onBlurCapture: () => (isFocusInsideReactTreeRef.current = false),
  };
}

import {
  ActionSheet,
  ActionSheetProps,
  IconButton,
  CrossIcon,
} from '@setel/web-ui';
import cx from 'classnames';
import * as React from 'react';
import { useZendeskWidgetSettings } from '~/features/Zendesk';

export interface MobileMenuProps extends ActionSheetProps {
  onBack?: () => void;
}

export function MobileMenu({
  overlayClassName,
  className,
  children,
  onBack,
  ...props
}: MobileMenuProps) {
  const { hide, show } = useZendeskWidgetSettings();
  // Hide chat bubble when open megamenu on phone
  React.useEffect(() => {
    if (props.isOpen) {
      hide();
      return () => show();
    }
  }, [props.isOpen]);

  return (
    <ActionSheet
      overlayClassName={cx(
        'animate-none overflow-hidden flex flex-col',
        overlayClassName
      )}
      className={cx(
        'flex flex-col rounded-none md:rounded-lg animate-none my-auto md:w-[375px] sm:w-full',
        className
      )}
      {...props}
      hideCloseButton
    >
      <ActionSheet.Header
        className="py-6 px-5"
        leftNode={
          Boolean(onBack) ? (
            <IconButton className="w-6 h-6 text-gray-light" onClick={onBack}>
              <CrossIcon aria-label="Close" className="w-full h-full" />
            </IconButton>
          ) : null
        }
      >
        <img
          alt="Setel logo"
          src="/assets/logo.svg"
          width="60"
          height="20"
          className="md:hidden w-[84px]"
        />
      </ActionSheet.Header>
      <ActionSheet.Content className="flex-auto overflow-y-auto py-0">
        {children}
      </ActionSheet.Content>
    </ActionSheet>
  );
}

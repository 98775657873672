import { forwardRef, ReactNode } from 'react';
import cn from 'classnames';
import Slick, { Settings, Slider as SlickSlider } from '~/components/Slick';
import { useIsMounted } from '~/shared/hooks/use-is-mounted';

export type SliderProps = Settings & {
  adaptiveHeight?: boolean;
  fullHeight?: boolean;
  swipe?: boolean;
  arrows?: boolean;
  dots?: boolean;
  beforeChange?: (prevIndex: number, nextIndex: number) => void;
  children?: ReactNode[];
};

export const Slider = forwardRef<SlickSlider, SliderProps>(function Slider(
  { fullHeight, children, ...props },
  ref
) {
  const isMounted = useIsMounted();

  const classes = cn('transition ease-in-out', {
    'full-height-slider': fullHeight,
  });

  return isMounted ? (
    <Slick ref={ref} className={classes} {...props}>
      {children}
    </Slick>
  ) : null;
});

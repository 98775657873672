import { useFormik } from 'formik';
import { ChangeEvent, ComponentType, forwardRef } from 'react';
import * as React from 'react';
import { FormControl, FormControlProps } from '../../components';

type FormFieldProps = Pick<
  ReturnType<typeof useFormik>,
  'setFieldValue' | 'getFieldMeta' | 'getFieldProps'
> &
  FormControlProps & {
    name: string;
    component: ComponentType<React.PropsWithChildren<any>>;
    componentParams?: any;
    useSetValue?: boolean;
    className?: string;
    placeholder?: string;
  };

export const FormField = forwardRef<any, FormFieldProps>(
  (
    {
      label,
      helperText,
      inlineHelperText,
      name,
      setFieldValue,
      getFieldMeta,
      getFieldProps,
      component: Component,
      componentParams,
      useSetValue,
      className,
      placeholder,
    },
    ref
  ) => {
    const meta = getFieldMeta(name);
    const field = getFieldProps(name);
    const error = meta.touched ? meta.error : '';
    return (
      <FormControl
        error={error}
        htmlFor={name}
        label={label}
        helperText={helperText}
        inlineHelperText={inlineHelperText}
        className={className}
      >
        <Component
          {...field}
          {...componentParams}
          placeholder={placeholder}
          id={name}
          ref={ref}
          error={error}
          onChange={(value: ChangeEvent | any) =>
            useSetValue
              ? setFieldValue(name, value, true)
              : field.onChange(value)
          }
        />
      </FormControl>
    );
  }
);

import classNames from 'classnames';
import { Icon, IconProp } from '~/components/Icon';
import { Size } from '~/components/theme';
import { type ComponentPropsWithoutRef, forwardRef } from 'react';
import { hasUtilClass } from '@setel/web-ui';

type GhostButtonProps = ComponentPropsWithoutRef<'button'> & {
  iconClassName?: string;
  leftIcon?: IconProp['name'];
  leftIconUrl?: string;
};

export const GhostButton = forwardRef<HTMLButtonElement, GhostButtonProps>(
  (
    { className, iconClassName, onClick, children, leftIcon, leftIconUrl },
    ref
  ) => (
    <button
      type="button"
      className={classNames(
        'flex items-center p-2 hover:bg-gray-light-badge md:rounded cursor-pointer focus:outline-none focus-visible:ring-brand focus-visible:ring-2',
        !hasUtilClass(className, 'text-') && 'text-gray-dark',
        className
      )}
      ref={ref}
      onClick={onClick}
    >
      {leftIconUrl ? (
        <Icon
          src={leftIconUrl}
          className={classNames('text-gray-medium mr-2', iconClassName)}
          size={Size.BASE}
        />
      ) : (
        leftIcon && (
          <Icon
            name={leftIcon}
            className={classNames('text-gray-medium mr-2', iconClassName)}
            size={Size.BASE}
          />
        )
      )}
      {children}
    </button>
  )
);

import { Size } from '~/components';
import { Icon } from '~/components/Icon';
import { isAndroid, CustomView, isIOS, getUA } from 'react-device-detect';
import { useIsMounted } from '~/shared/hooks';
import { useTranslation } from 'react-i18next';
import { Heading } from '@setel/web-ui';

export const GET_APP_LINK = 'https://app.adjust.com/q4brdgl';

type DownloadViaLinkProps = {
  showDownloadLinks: boolean;
};

export const DownloadViaLink = ({
  showDownloadLinks,
}: DownloadViaLinkProps) => {
  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const isHuawei =
    isMounted &&
    ['huawei', 'hms', 'gms']
      .map((x) => getUA.toLowerCase().includes(x))
      .some((x) => x);

  const content = [
    t('getAppBanner.content-1'),
    t('getAppBanner.content-2'),
    t('getAppBanner.content-3'),
    t('getAppBanner.content-4'),
    t('getAppBanner.content-5'),
  ];

  return (
    <div>
      <div>
        <Heading size="32">{t('getAppBanner.title')}</Heading>
        <ul className="list-none w-[fit-content] mx-auto md:ml-0 md:mr-auto space-y-1 mt-3">
          {content.map((item, index) => (
            <li className="flex items-center" key={index}>
              <Icon
                size={Size.BASE}
                name="status"
                className="flex-none fill-current h-8 text-brand inline"
              />
              <span className="ml-2 text-[15px] text-gray-light">{item}</span>
            </li>
          ))}
        </ul>
      </div>
      {isMounted && showDownloadLinks && (
        <div className="flex justify-center items-center mt-4">
          <CustomView condition={isIOS}>
            <a href={GET_APP_LINK} target="_blank" rel="noopener noreferrer">
              <img src="/assets/appstore.svg" className="max-w-full" />
            </a>
          </CustomView>
          <CustomView condition={isAndroid && !isHuawei}>
            <a href={GET_APP_LINK} target="_blank" rel="noopener noreferrer">
              <img src="/assets/playstore.svg" className="max-w-full" />
            </a>
          </CustomView>
          <CustomView condition={isHuawei}>
            <a href={GET_APP_LINK} target="_blank" rel="noopener noreferrer">
              <img src="/assets/appgallery.svg" className="max-w-full" />
            </a>
          </CustomView>
        </div>
      )}
    </div>
  );
};

import { forwardRef, useEffect } from 'react';
import { Button, ButtonProps, CheckIcon, Loader } from '@setel/web-ui';
import cx from 'classnames';

export interface ProgressButtonProps extends ButtonProps {
  /**
   * if `true`, show white checkmark for `primary`, green checkmark for `outline` variant.
   */
  isSuccess?: boolean;
  /**
   * called if `isSuccess` is `true`, `afterSuccessDelay`.
   * Defaults to `3000` milliseconds.
   */
  afterSuccess?: () => void;
  /**
   * delay `afterSuccess` for this number of milliseconds.
   */
  afterSuccessDelay?: number;
  /**
   * accessible label once progress succeeded
   */
  successLabel?: string;
}

/**
 * Extend @setel/web-ui Button to handle `success` consistently.
 */
export const ProgressButton = forwardRef<
  HTMLButtonElement,
  ProgressButtonProps
>(
  (
    {
      className,
      children,
      variant = 'primary',
      isLoading,
      isSuccess = false,
      afterSuccess,
      afterSuccessDelay = 3000,
      successLabel = 'Success',
      ...props
    },
    ref
  ) => {
    useEffect(() => {
      if (isSuccess && afterSuccess) {
        const interval = setTimeout(afterSuccess, afterSuccessDelay);
        return () => {
          clearInterval(interval);
        };
      }
    }, [isSuccess]);

    const inProgress = isLoading || (isSuccess && Boolean(afterSuccess));

    return (
      <Button
        ref={ref}
        className={cx(
          'select-none',
          inProgress && 'pointer-events-none',
          className
        )}
        variant={variant}
        isLoading={isLoading}
        aria-busy={isLoading}
        {...props}
      >
        {isSuccess && (
          <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
            {variant === 'primary' && (
              <CheckIcon
                className=" text-white w-6 h-6"
                aria-label={successLabel}
              />
            )}
            {variant === 'outline' && (
              <Loader
                type="checked"
                className="w-6 h-6"
                aria-label={successLabel}
              />
            )}
          </div>
        )}
        <div className={cx((isSuccess || isLoading) && 'text-transparent')}>
          {children}
        </div>
      </Button>
    );
  }
);

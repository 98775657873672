import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum ZendeskTagsName {
  LOGIN_OTP_REQUEST = 'web_registration_login_request_otp',
  LOGIN_OTP_CONFIRM = 'web_registration_login_confirm',
  GET_USER_DETAILS = 'web_registration_get_user_details',
  REWARDS_HOME = 'web_rewards_home',
  DEAL_DETAIL = 'web_deal_detail',
  LOYALTY_TRANSACTION_LIST = 'web_loyalty_transaction_list',
  LOYALTY_CARD_DETAIL = 'web_loyalty_card_detail',
  LOYALTY_CARD_VIRTUAL_ACTIVATION = 'web_loyalty_card_virtual_activation',
  LOYALTY_REDEEM_PENDING = 'web_loyalty_redeem_pending',
  LOYALTY_REDEEM_PROCESSED = 'web_web_loyalty_redeem_processed',
  LOYALTY_REDEEM_FAIL = 'web_loyalty_redeem_fail',
}

export type ZendeskTagState = {
  currentTag: undefined | ZendeskTagsName;
  prevTag: undefined | ZendeskTagsName;
};

const initialState: ZendeskTagState = {
  currentTag: undefined,
  prevTag: undefined,
};

export const zendeskTagSlice = createSlice({
  name: 'zendeskTagState',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<ZendeskTagState>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    reset: () => initialState,
  },
});

import { FC, AriaAttributes } from 'react';
import cn from 'classnames';
import { SelectProps } from '../select.type';
import { Icon } from '~/components/Icon';
import { classes } from '~/components/Form/Input';

export const SelectDropdown: FC<
  React.PropsWithChildren<SelectProps & Pick<AriaAttributes, 'aria-label'>>
> = ({
  name,
  options,
  value,
  onChange,
  className,
  wrapperClassName,
  ...props
}) => {
  return (
    <div
      className={cn('relative w-full hidden md:inline-block', wrapperClassName)}
    >
      <select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={cn(classes, className, 'block appearance-none')}
        aria-label={props['aria-label']}
        name={name}
      >
        {options.map(({ label, value }) => (
          <option key={label} {...(value && { value })}>
            {label}
          </option>
        ))}
      </select>
      <span
        role="presentation"
        className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-800"
      >
        <Icon name="arrow" aria-label="chevron down icon" />
      </span>
    </div>
  );
};

import { FC } from 'react';
import * as React from 'react';
import cn from 'classnames';

export type CardTitleProps = {
  className?: string;
};

export const CardTitle: FC<React.PropsWithChildren<CardTitleProps>> = ({
  children,
  className,
}) => {
  return (
    <p className={cn('text-gray-dark font-medium text-lg', className)}>
      {children}
    </p>
  );
};

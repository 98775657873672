import { RootNavigationRoute } from './navigation.const';
import { useRouteMatch } from '~/shared/hooks/use-route-match';

export const useTopNavVisibility = () =>
  !!useRouteMatch([
    RootNavigationRoute.PROFILE,
    RootNavigationRoute.DEAL,
    RootNavigationRoute.INBOX_VIEW_MESSAGE,
    RootNavigationRoute.MESRA_CARD_REGISTER_LANDING_PAGE,
  ]);

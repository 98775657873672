import {
  CSSProperties,
  IframeHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { noop, useTransientState, isDefined } from '@setel/web-ui';
import { useUpdateEffect } from '@reach/utils';
import cn from 'classnames';
import { useBodyScrollLock, useWindowSize } from '~/shared';
import { BottomOverlay, Controls } from './Controls';
import { PlayerProvider } from './context';
import { PlayerState } from './types';

interface YoutubePlaylistPlayerProps
  extends Omit<
    IframeHTMLAttributes<HTMLIFrameElement>,
    'src' | 'allow' | 'onPlay'
  > {
  videoId?: string;
  videoTitle?: string;
  playlistId?: string;
  videoIndex?: number;
  onVideoIndexChange?: (i: number) => void;
  onVideoEnded?: () => void;
  onPlayerReady?: (evt: YT.PlayerEvent) => void;
  onFullScreenToggled?: (fullscreen: boolean) => void;
  onPrev?: () => void;
  onNext?: () => void;
  onPlay?: () => void;
  canNext?: boolean;
  canPrev?: boolean;
  autoPlay?: boolean;
  fullScreen?: boolean;
  customControls?: boolean;
  wrapperClassName?: string;
}

export function YoutubePlayer({
  videoId,
  playlistId,
  videoIndex,
  videoTitle,
  onVideoIndexChange = noop,
  onVideoEnded = noop,
  onPlayerReady = noop,
  onFullScreenToggled = noop,
  onPlay = noop,
  onNext = noop,
  onPrev = noop,
  canNext,
  canPrev,
  autoPlay,
  fullScreen,
  customControls,
  className = '',
  wrapperClassName = '',
  ...props
}: YoutubePlaylistPlayerProps) {
  const { disable: disableBodyScroll, enable: enableBodyScroll } =
    useBodyScrollLock();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const playerRef = useRef<YT.Player | null>(null);
  const currentVideoRef = useRef(videoIndex);

  const [state, setState] = useState<PlayerState>(PlayerState.UNSTARTED);
  const [showFs, setShowFs] = useState(false);
  const [showOverlay, setShowOverlay] = useTransientState(
    false,
    2500 // overlay's auto-hide duration
  );

  // constraints
  const shouldFs = Boolean(showFs && fullScreen);
  const shouldOverlay = showOverlay || state === PlayerState.PAUSED;
  const showControls = [PlayerState.PLAYING, PlayerState.PAUSED].includes(
    state
  );

  // 'true' fit-to-screen height
  const { height } = useWindowSize(100);
  const vhStyle = { '--dh': height ? height + 'px' : '100vh' } as CSSProperties;

  useEffect(() => {
    async function load() {
      const YT = await loadYoutubeApi();
      if (!iframeRef.current) return;
      playerRef.current = new YT.Player(iframeRef.current, {
        playerVars: { origin: window.location.origin },
        events: {
          onReady: (evt) => {
            onPlayerReady(evt);
          },
          onStateChange: (evt) => {
            setState(evt.data);
          },
        },
      });
    }
    load();
  }, []);

  useUpdateEffect(() => {
    if (!playlistId) return;
    // Fires on state change & check for current playing index.
    // TODO: cleanup this logic to avoid bloating this component
    // .eg we might not need a ref for current video index
    const _videoIndex = playerRef.current?.getPlaylistIndex() ?? 0;
    if (_videoIndex !== currentVideoRef.current) {
      currentVideoRef.current = _videoIndex;
      onVideoIndexChange(_videoIndex);
    }
  }, [state, playlistId]);

  useUpdateEffect(() => {
    if (state === PlayerState.ENDED) {
      playerRef.current?.stopVideo();
      onVideoEnded();
    }
    if (state === PlayerState.PLAYING) {
      onPlay();
    }
  }, [state]);

  const play = useCallback(
    () =>
      isDefined(videoIndex)
        ? playerRef.current?.playVideoAt(videoIndex)
        : playerRef.current?.playVideo(),
    [videoIndex]
  );

  const prev = useCallback(() => {
    if (isDefined(playlistId)) {
      playerRef.current?.previousVideo();
      return;
    }
    playerRef.current?.pauseVideo();
    setShowFs(false);
    setShowOverlay(false);
    onPrev();
  }, [playlistId]);

  const next = useCallback(() => {
    if (isDefined(playlistId)) {
      playerRef.current?.previousVideo();
      return;
    }
    playerRef.current?.pauseVideo();
    setShowFs(false);
    setShowOverlay(false);
    onNext();
  }, [playlistId]);

  useUpdateEffect(() => {
    if (playlistId && isDefined(videoIndex)) {
      playerRef.current?.playVideoAt(videoIndex);
    }
  }, [videoIndex, playlistId]);

  // fullscreen-depended effects

  useUpdateEffect(() => {
    if (!fullScreen) {
      return;
    }
    if (state === PlayerState.PLAYING) {
      disableBodyScroll();
      setShowFs(true);
    }
    if (state === PlayerState.ENDED && videoId) {
      setShowFs(false);
    }
  }, [state, fullScreen]);

  useUpdateEffect(() => {
    if (!shouldFs) {
      enableBodyScroll();
    }
    onFullScreenToggled(shouldFs);
  }, [shouldFs]);

  if (!playlistId && !videoId) return null;

  if (!fullScreen) {
    return (
      <iframe
        ref={iframeRef}
        src={getYTEmbedUrl({ playlistId, videoId })}
        frameBorder="0"
        allowFullScreen
        allow={[
          'accelerometer',
          'encrypted-media',
          'gyroscope',
          autoPlay && 'autoplay',
        ]
          .filter((v) => Boolean(v))
          .join(';')}
        className={cn(['h-full w-full', className])}
        tabIndex={-1}
        {...props}
      />
    );
  }

  return (
    <PlayerProvider
      value={{
        state,
        player: playerRef.current!,
        fullScreen: shouldFs,
        setShowFullScreen: setShowFs,
        canNext,
        canPrev,
      }}
    >
      <div
        className={cn(
          shouldFs
            ? 'fixed z-100 top-0 left-0 w-screen h-[var(--dh)] bg-black !rounded-none'
            : 'relative w-full h-full',
          wrapperClassName
        )}
        style={{
          ...vhStyle,
          ...(shouldFs && {
            // disallow pan zoom during fullscreen mode
            touchAction: 'none',
          }),
        }}
      >
        <iframe
          ref={iframeRef}
          src={getYTEmbedUrl({ playlistId, videoId, customControls })}
          frameBorder="0"
          allowFullScreen
          allow={[
            'accelerometer',
            'encrypted-media',
            'gyroscope',
            autoPlay && 'autoplay',
          ]
            .filter((v) => Boolean(v))
            .join(';')}
          className={cn(['z-[-1] h-full w-full', className])}
          tabIndex={-1}
          {...props}
        />

        <div
          className={cn([
            'top-0 left-0 w-full text-white focus:outline-none',
            shouldFs ? 'fixed h-[var(--dh)]' : 'absolute h-full',
            shouldOverlay ? 'flex flex-col bg-black bg-opacity-50' : '',
            [
              PlayerState.UNSTARTED,
              PlayerState.ENDED,
              PlayerState.CUED,
            ].includes(state) && 'pointer-events-none',
          ])}
          tabIndex={-1}
          role="button"
          onClick={(e) => {
            e.stopPropagation();
            setShowOverlay((v) => !v);
          }}
        >
          <div
            className={cn([
              'absolute top-0 left-0 w-screen p-4',
              state === PlayerState.PLAYING && shouldOverlay
                ? 'block'
                : 'hidden',
            ])}
          >
            {videoTitle && (
              <strong className="font-normal text-base">{videoTitle}</strong>
            )}
          </div>

          <Controls
            visible={shouldOverlay && showControls}
            onClick={() => setShowOverlay(true)}
            onPlayClick={play}
            onPrevClick={prev}
            onNextClick={next}
          />
          <BottomOverlay
            visible={shouldFs && shouldOverlay}
            onClick={() => setShowOverlay(true)}
          />
        </div>
      </div>
    </PlayerProvider>
  );
}

export function getYTEmbedUrl({
  playlistId,
  videoId,
  autoPlay,
  customControls,
}: Pick<
  YoutubePlaylistPlayerProps,
  'playlistId' | 'videoId' | 'autoPlay' | 'customControls'
>) {
  const url = new URL('https://www.youtube-nocookie.com/embed');
  const params = new URLSearchParams({
    // https://developers.google.com/youtube/player_parameters
    ...(playlistId && {
      list: playlistId,
      listType: 'playlist',
    }),
    enablejsapi: '1',
    modestbranding: '1',
    iv_load_policy: '3',
    showinfo: '0',
    rel: '0',
    playsinline: '1',
    controls: customControls ? '0' : '1',
    fs: '0',
    disablekb: '1',
    autoplay: autoPlay ? '1' : '0',
  });
  url.search = params.toString();
  if (videoId) url.pathname = `/embed/${videoId}`;

  return url.toString();
}

async function loadYoutubeApi(): Promise<typeof YT> {
  const { default: loader } = await import('youtube-iframe');
  return new Promise((resolve) => {
    loader.load((yt) => {
      resolve(yt);
    });
  });
}

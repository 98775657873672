import * as React from 'react';

type Props = {
  children: React.ReactNode | ((errorState: State) => React.ReactNode);
} & (
  | {
      fallback?: React.ReactNode;
      fallbackRender?: never;
    }
  | {
      fallback?: never;
      fallbackRender?: (errorState: {
        error: NonNullable<State['error']>;
        errorInfo: State['errorInfo'];
      }) => React.ReactNode;
    }
);

interface State {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}

class ErrorBoundaryImpl extends React.Component<Props, State> {
  public state: State = { error: null, errorInfo: null };

  public static getDerivedStateFromError(
    error: Error,
    errorInfo: React.ErrorInfo
  ): State {
    // Update state so the next render will show the fallback UI.
    return { error, errorInfo };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);

    if (typeof window !== 'undefined') {
      window.newrelic?.noticeError(
        error,
        errorInfo?.componentStack
          ? { componentStack: errorInfo.componentStack }
          : undefined
      );
    }
  }

  public render() {
    const { error, errorInfo } = this.state;

    if (typeof this.props.children === 'function') {
      return this.props.children({ error, errorInfo });
    }

    if (error) {
      if (this.props.fallbackRender) {
        this.props.fallbackRender({ error, errorInfo });
      }
      return this.props.fallback;
    }

    return this.props.children;
  }
}

export const ErrorBoundary = Object.assign(ErrorBoundaryImpl, {
  DefaultFallback,
});

function DefaultFallback({
  error,
  errorInfo,
}: Parameters<NonNullable<Props['fallbackRender']>>[0]) {
  return (
    <div>
      <p className="text-error-500">Something went wrong</p>
      {process.env.NODE_ENV !== 'production' ? (
        <details>
          {error.toString()}
          <br />
          <pre style={{ whiteSpace: 'pre-wrap' }}>
            {errorInfo?.componentStack}
          </pre>
        </details>
      ) : null}
    </div>
  );
}

import { matchPath } from '@remix-run/react';
import { MobileMenuItem, NavLink } from '~/components';
import { NavLinkPropsWithLabel } from '~/components/TopNav';
import { useVariables, WebVariableType } from '~/domain/variable';
import { ProfileRoute } from './profile.const';
import { runTimeConfig as config } from '~/shared/runtime-config';

type ProfileMenuProps = {
  highlightActive?: boolean;
  hideDivider?: boolean;
};

type NavLinkPropsWithDivider =
  | NavLinkPropsWithLabel
  | {
      divider: true;
    };

const ITEMS: NavLinkPropsWithDivider[] = [
  {
    href: ProfileRoute.BADGES,
    label: 'Badges',
    icon: 'badge',
  },
  {
    href: ProfileRoute.VOUCHERS,
    label: 'My vouchers',
    icon: 'voucher',
  },
  {
    href: ProfileRoute.SUBMIT_RECEIPT,
    label: 'Submit receipts',
    icon: 'receipt',
  },
  {
    divider: true,
  },
  {
    href: ProfileRoute.MESRA_HISTORY,
    label: 'Mesra history',
    icon: 'history',
    isActive: (pathname) => {
      return (
        !!matchPath(ProfileRoute.MESRA_HISTORY, pathname) ||
        !!matchPath(ProfileRoute.MESRA_HISTORY_EARNINGS, pathname) ||
        !!matchPath(ProfileRoute.MESRA_HISTORY_REDEMPTION, pathname)
      );
    },
  },
  {
    href: ProfileRoute.MESRA_CARD,
    label: 'Mesra card',
    icon: 'card',
  },
  {
    divider: true,
  },
  {
    href: ProfileRoute.ACCOUNT_INFO,
    label: 'Account info',
    icon: 'user',
  },
  {
    href: ProfileRoute.SECURITY,
    label: 'Security',
    icon: 'lock',
  },
  {
    href: ProfileRoute.SESSIONS,
    label: 'Sessions',
    icon: 'desktop',
  },
  {
    href: ProfileRoute.NOTIFICATIONS,
    label: 'Notification settings',
    icon: 'bell',
  },
];

export const ProfileMenu = ({
  highlightActive,
  hideDivider,
}: ProfileMenuProps) => {
  const { data: variables } = useVariables();
  return (
    <>
      {ITEMS.map((item, index) => {
        if ('divider' in item) {
          return hideDivider ? null : (
            <div key={index} className="my-4 border-b border-gray-300" />
          );
        }
        const { href: to, label, icon, isActive } = item;
        if (to === ProfileRoute.SESSIONS && !config.SESSIONS_ENABLED) {
          return null;
        }

        // check variable if submit receipt is disabled so that it can be hidden
        if (
          to === ProfileRoute.SUBMIT_RECEIPT &&
          !variables?.[WebVariableType.SUBMIT_RECEIPT_FEATURE]?.value
        ) {
          return null;
        }

        return (
          <NavLink
            key={to as string}
            className="block rounded-xl"
            tabIndex={-1}
            activeClassName={highlightActive ? 'bg-gray-200' : ''}
            href={to}
            {...(!!isActive && { isActive })}
          >
            <MobileMenuItem leftIcon={icon} children={label} />
          </NavLink>
        );
      })}
    </>
  );
};
